import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import TableHeader from "components/TableHeader";
import { useMembership } from "hook/membership-hook/apiCalls";
import { useState } from "react";
import EditMember from "./EditMember";
import CreateMembership from "./Create";

const AdminMasterMember = () => {
  const [memberId, setMemberId] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { data, isLoading, isFetching, refetch } = useMembership();

  const onCancel = () => {
    setShowCreate(false);
    setShowEdit(false);
  };

  const onSubmit = () => {
    onCancel();
    refetch();
  };

  const handleClick = (memberId) => {
    setMemberId(memberId);
    setShowEdit(true);
  };

  return (
    <div>
      <TableHeader
        title="Membership Type"
        addBtn={data?.data?.data?.length >= 2 ? false : true}
        onClick={() => setShowCreate(true)}
      />
      {isLoading || isFetching ? (
        <div className="max-w-[500px]">
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <div className="flex flex-wrap gap-10">
          {data?.data?.data?.map((member) => (
            <div
              key={member.id}
              className="flex-1 p-8 my-6 border border-gray-300 rounded-md max-w-[400px]"
            >
              <div className="pb-2 1 border-b">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-bold">{member.name}</h2>
                  <Button
                    className="border-orange-500 flex items-center !px-4"
                    onClick={() => handleClick(member.id)}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                </div>
                <p>{member.cost}</p>
              </div>
              <p className="my-2">Benefits: </p>
              <ul>
                {member.benefits?.map((benefit, index) => (
                  <li key={index} className="mb-1">
                    <CheckOutlined className="mr-2" />
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      <CreateMembership
        show={showCreate}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
      <EditMember
        id={memberId}
        show={showEdit}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default AdminMasterMember;
