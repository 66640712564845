import { DatePicker, Input, Popconfirm, Space, Table, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import TableHeader from "components/TableHeader";
import {
  setFetchMatching,
  setMatchingId,
  setShowCreate,
  setShowEdit,
} from "features/business-matching/business-matching-slice";
import { useBusinessMatchingPagination } from "hook/business-matching-hook/apiCalls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateBusinessMatching from "./Create";
import EditBusinessMatching from "./Edit";

const MasterBusinessMatching = () => {
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState({ from: "", to: "" });
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchMatching } = useSelector((state) => state.businessMatching);

  const { data, isLoading, refetch } = useBusinessMatchingPagination({
    date,
    keyword,
    dataTable,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchMatching(false));
  }, [refetch, fetchMatching, dispatch]);

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "title" },
    { title: "Lokasi", dataIndex: "location" },
    { title: "Speaker", dataIndex: "speaker" },
    {
      title: "Homepage",
      dataIndex: "show_on_home",
      render: (x) => (x === true ? "Tampil" : "Tidak"),
    },
    { title: "Tanggal Mulai", dataIndex: "from_date" },
    { title: "Tanggal Selesai", dataIndex: "to_date" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({ url: "/api/event-offlines/", dataId: id, refetch })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setShowEdit(true));
              dispatch(setMatchingId(id));
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${id}/contributors`)}
          >
            Kontributor
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader
        title="Business Matching"
        onClick={() => dispatch(setShowCreate(true))}
      />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
        {/* <div className="filter">
          <Select
            style={{ width: "100%" }}
            placeholder="Tipe Event"
            onChange={(e) => setType(e)}
            options={[
              { value: "", label: "All" },
              { value: 1, label: "Upcoming" },
              { value: 0, label: "Past" },
            ]}
          />
        </div> */}
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Awal"
            style={{ width: "100%" }}
            onChange={(e) => setDate((curr) => ({ ...curr, from: e }))}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Akhir"
            style={{ width: "100%" }}
            onChange={(e) => setDate((curr) => ({ ...curr, to: e }))}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 1000 }}
      />

      <CreateBusinessMatching />
      <EditBusinessMatching />
    </div>
  );
};

export default MasterBusinessMatching;
