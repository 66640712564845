import { Form, Input, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { IoInformationCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

const FormJoinFreeLearnings = ({ idLearnings, show, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  // const info = () => {
  //   Modal.info({
  //     content: (
  //       <div className="text-xl">
  //         <p>Anda telah mengikuti kelas ini</p>
  //       </div>
  //     ),
  //     onOk() {
  //       navigate("link");
  //     },
  //     okText: "Lihat Link",
  //   });
  // };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      setLoading(true);
      await axios.post(
        `${REACT_APP_HOST_API}/api/biznids-learning/guest-free`,
        { ...values, freeWebinarId: idLearnings }
      );
      onCancelModal();
      navigate("link");
    } catch (error) {
      error.response
        ? error.response.data.message === "Gagal join webinar"
          ? setShowModal(true)
          : alert(error.response.data.message)
        : alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <>
      <Modal
        open={show}
        okText="Submit"
        cancelText="Cancel"
        onOk={onSubmit}
        onCancel={onCancelModal}
        okButtonProps={{ loading }}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              { required: true },
              { min: 8 },
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={showModal}
        okText="Lihat link"
        cancelText="Close"
        onOk={() => navigate("link")}
        onCancel={() => setShowModal(false)}
        closable={false}
      >
        <IoInformationCircle className="text-4xl text-blue-500" />
        <h1 className="text-2xl font-bold mt-4 mb-12 text-center">
          Anda telah mengikuti kelas tersebut
        </h1>
      </Modal>
    </>
  );
};

export default FormJoinFreeLearnings;
