import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Skeleton,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import dayjs from "dayjs";
import {
  setFetchFreeLearnings,
  setShowEditFreeLearnings,
} from "features/learnings/learnings-slice";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const { TextArea } = Input;

const { REACT_APP_HOST_API: url } = process.env;

const format = "YYYY-MM-DD HH:mm:ss";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditFreeLearnings = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [bannerList, setBannerList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newBannerList, setNewBannerList] = useState([]);
  const [newFileList, setNewFileList] = useState([]);

  const dispatch = useDispatch();
  const { freeLearningsId, showEditFreeLearnings } = useSelector(
    (state) => state.learnings
  );

  const fetchDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        url + `/api/biznids-learning/free/${freeLearningsId}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      if (data?.data?.banner) {
        const image = data.data.banner;
        const newImage = {
          uid: 1,
          name: image.split("events")[1].split("?")[0].substring(1),
          status: "done",
          url: image,
        };

        setBannerList([newImage]);
      }

      if (data?.data?.photo_speaker) {
        const image = data.data.photo_speaker;
        const newImage = {
          uid: 1,
          name: image.split("events")[1].split("?")[0].substring(1),
          status: "done",
          url: image,
        };

        setFileList([newImage]);
      }

      form.setFieldsValue({
        title: data?.data?.title,
        description: data?.data?.description,
        speaker: data?.data?.speaker,
        position: data?.data?.position,
        link: data?.data?.link,
        fromDate: dayjs(moment(data?.data?.from_date).format(format)),
        toDate: dayjs(moment(data?.data?.to_date).format(format)),
        showOnHome: data?.data?.show_on_home,
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }, [form, freeLearningsId]);

  useEffect(() => {
    if (showEditFreeLearnings) fetchDetail();

    return () => setNewData({});
  }, [showEditFreeLearnings, fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setBannerList([]);
    setNewBannerList([]);
    setFileList([]);
    setNewFileList([]);
    dispatch(setShowEditFreeLearnings(false));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = (param) =>
    (newData[param.target.name] = param.target.value);

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        if (
          Object.keys(newData).length === 0 &&
          newFileList.length === 0 &&
          newBannerList.length === 0
        ) {
          alert("Tidak ada yang dirubah");
          return;
        }

        setLoading(true);
        try {
          const { data } = await axios.put(
            url + "/api/biznids-learning/free/" + freeLearningsId,
            newFileList.length === 0 && newBannerList.length === 0
              ? { ...newData }
              : {
                  ...newData,
                  photoSpeaker: newFileList[0],
                  banner: newBannerList[0],
                },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          dispatch(setFetchFreeLearnings(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showEditFreeLearnings}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      style={{ top: 20 }}
      width={800}
    >
      {isLoading ? (
        new Array(5).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item label="Banner">
            <Upload
              fileList={bannerList}
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              onRemove={() => false}
              onChange={(file) => {
                if (file?.file.size > 3000000) return;
                else setBannerList(file.fileList);
              }}
              beforeUpload={(file) => {
                if (file.size <= 3000000) {
                  setBannerList([file]);
                  setNewBannerList([file]);
                  return false;
                }
                if (file.size > 3000000) {
                  message.error("Maks File 3MB");
                }
              }}
            >
              {bannerList?.length > 1
                ? null
                : bannerList.length === 0
                ? uploadButton
                : "Ganti"}
            </Upload>
          </Form.Item>
          <Form.Item name="title" label="Nama Learning" rules={[{ min: 3 }]}>
            <Input name="title" onChange={handleChange} />
          </Form.Item>
          <Form.Item name="description" label="Deskripsi" rules={[{ min: 3 }]}>
            <TextArea name="description" rows={5} onChange={handleChange} />
          </Form.Item>

          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item
              name="fromDate"
              label="Tanggal Mulai"
              rules={[{ required: true, message: "harap diisi" }]}
            >
              <DatePicker
                showTime
                format={format}
                placeholder="Pilih Tanggal"
                onChange={(e) => (newData.fromDate = e.format(format))}
              />
            </Form.Item>
            <Form.Item
              name="toDate"
              label="Tanggal Selesai"
              rules={[{ required: true, message: "harap diisi" }]}
            >
              <DatePicker
                showTime
                format={format}
                placeholder="Pilih Tanggal"
                onChange={(e) => (newData.toDate = e.format(format))}
              />
            </Form.Item>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item
              label="Link Learning"
              name="link"
              rules={[{ required: true }]}
            >
              <Input name="link" onChange={handleChange} />
            </Form.Item>
            <Form.Item name="showOnHome" label="Tampil di Homepage">
              <Radio.Group name="showOnHome" onChange={handleChange}>
                <Radio value={true}>tampil</Radio>
                <Radio value={false}>tidak</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <Form.Item label="Foto Speaker">
            <Upload
              fileList={fileList}
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              onRemove={() => false}
              onChange={(file) => {
                if (file?.file.size > 3000000) return;
                else setFileList(file.fileList);
              }}
              beforeUpload={(file) => {
                if (file.size <= 3000000) {
                  setFileList([file]);
                  setNewFileList([file]);
                  return false;
                }
                if (file.size > 3000000) {
                  message.error("Maks File 3MB");
                }
              }}
            >
              {fileList?.length > 1
                ? null
                : fileList.length === 0
                ? uploadButton
                : "Ganti"}
            </Upload>
          </Form.Item>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item name="speaker" label="Nama Speaker">
              <Input name="speaker" onChange={handleChange} />
            </Form.Item>
            <Form.Item name="position" label="Jabatan/ Posisi Speaker">
              <Input name="position" onChange={handleChange} />
            </Form.Item>
          </div>
        </Form>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default EditFreeLearnings;
