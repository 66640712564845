import Page404 from "components/Page404";
import { ScrollToTop } from "components/ScrollToTop";
import RequireToken from "components/helper/RequireToken";
import TokenIsTrue from "components/helper/TokenIsTrue";
import LayoutAdmin from "layouts/LayoutAdmin";
import LayoutContent from "layouts/LayoutContent";
import LayoutCorporate from "layouts/LayoutCorporate";
import LayoutUser from "layouts/LayoutUser";
import AboutUs from "pages/about";
import DetailMasterCorporate from "pages/admin/corporates/DetailMasterCorporate";
import MasterCorporate from "pages/admin/corporates/Master";
import DashboardAdmin from "pages/admin/dashboard/DashboardAdmin";
import MasterBusinessMatching from "pages/admin/events/business-matching/Master";
import MasterMatchingContributors from "pages/admin/events/business-matching/contributors/Master";
import MasterCorporateBookings from "pages/admin/events/business-matching/contributors/bookings";
import MasterFreeLearnings from "pages/admin/events/learnings/free";
import MasterFreeParticipants from "pages/admin/events/learnings/free/FreeParticipants";
import MasterPaidLearnings from "pages/admin/events/learnings/paid";
import MasterPaidParticipants from "pages/admin/events/learnings/paid/PaidParticipants";
import MasterTradeMission from "pages/admin/events/trade-mission";
import MasterHeadlines from "pages/admin/headlines/Master";
import AdminMasterMember from "pages/admin/member";
import GoldSubmission from "pages/admin/member/submission";
import AdminMasterUsers from "pages/admin/member/users";
import DetailMasterUsers from "pages/admin/member/users/Detail";
import CreateNews from "pages/admin/news/CreateNews";
import Detail from "pages/admin/news/Detail";
import EditNews from "pages/admin/news/EditNews";
import MasterNews from "pages/admin/news/MasterNews";
import MasterProjects from "pages/admin/projects";
import CreateProjects from "pages/admin/projects/Create";
import DetailProjects from "pages/admin/projects/Detail";
import EditProjects from "pages/admin/projects/Edit";
import MasterTestimonial from "pages/admin/testimonial/MasterTestimonial";
import MasterTestimonialDetail from "pages/admin/testimonial/MasterTestimonialDetail";
import UserAuth from "pages/auth/UserAuth";
import BankAccounts from "pages/banks";
import BusinessMatching from "pages/business-matching";
import DetailBusinessMatching from "pages/business-matching/detail/DetailBusinessMatching";
import PaymentMatching from "pages/business-matching/detail/PaymentMatching";
import DashboardCoporate from "pages/corporate/dashboard";
import CorporateMasterBusinessMatching from "pages/corporate/events/business-matching/Master";
import CorporateMasterBookingMatching from "pages/corporate/events/business-matching/bookings/Master";
import CorporateMasterProducts from "pages/corporate/products";
import Learnings from "pages/learnings";
import DetailFreeLearnings from "pages/learnings/free/Detail";
import LinkFreeLearnings from "pages/learnings/free/LinkFreeWebinars";
import DetailPaidLearnings from "pages/learnings/paid/Detail";
import PaymentLearnings from "pages/learnings/paid/PaymentLearnings";
import Partners from "pages/partners";
import DetailPartners from "pages/partners/Detail";
import DetailProduct from "pages/partners/DetailProduct";
import Projects from "pages/projects";
import DetailProjectsPage from "pages/projects/detail";
import TradeMissions from "pages/trade-mission";
import DetailTradeMission from "pages/trade-mission/detail/DetailTradeMission";
import MasterUser from "pages/user/dashboard";
import UserMasterBusinessMatching from "pages/user/events/business-matching/Master";
import UserMasterCorporate from "pages/user/events/business-matching/bookings/Master";
import UserPaidLearnings from "pages/user/events/learnings/paid";
import { lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const HomePage = lazy(() => import("pages/home/HomePage"));
const LoginAdmin = lazy(() => import("pages/login-admin/LoginAdmin"));
const News = lazy(() => import("pages/news-info/News"));
const DetailNews = lazy(() => import("pages/news-info/detail/DetailNews"));

const RoutesComponent = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Page404 />} />

        {/* corporate */}
        <Route
          path="corporate"
          element={
            <RequireToken>
              <LayoutCorporate>
                <DashboardCoporate />
              </LayoutCorporate>
            </RequireToken>
          }
        />
        <Route
          path="corporate/products"
          element={
            <RequireToken>
              <LayoutCorporate>
                <CorporateMasterProducts />
              </LayoutCorporate>
            </RequireToken>
          }
        />
        <Route
          path="corporate/business-matching"
          element={
            <RequireToken>
              <LayoutCorporate>
                <CorporateMasterBusinessMatching />
              </LayoutCorporate>
            </RequireToken>
          }
        />
        <Route
          path="corporate/business-matching/:matching_id/bookings"
          element={
            <RequireToken>
              <LayoutCorporate>
                <CorporateMasterBookingMatching />
              </LayoutCorporate>
            </RequireToken>
          }
        />
        {/* <Route
          path="corporate/learnings"
          element={
            <RequireToken>
              <LayoutCorporate>
                <CorporatePaidLearnings />
              </LayoutCorporate>
            </RequireToken>
          }
        /> */}

        {/* user start */}
        <Route
          path="user"
          element={
            <RequireToken>
              <LayoutUser>
                <MasterUser />
              </LayoutUser>
            </RequireToken>
          }
        />
        <Route
          path="user/business-matching"
          element={
            <RequireToken>
              <LayoutUser>
                <UserMasterBusinessMatching />
              </LayoutUser>
            </RequireToken>
          }
        />
        <Route
          path="user/business-matching/:event_id/bookings"
          element={
            <RequireToken>
              <LayoutUser>
                <UserMasterCorporate />
              </LayoutUser>
            </RequireToken>
          }
        />

        <Route
          path="user/learnings"
          element={
            <RequireToken>
              <LayoutUser>
                <UserPaidLearnings />
              </LayoutUser>
            </RequireToken>
          }
        />

        {/* admin start */}
        <Route
          path="admin"
          element={
            <RequireToken>
              <LayoutAdmin>
                <DashboardAdmin />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/headline"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterHeadlines />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/member"
          element={
            <RequireToken>
              <LayoutAdmin>
                <AdminMasterMember />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/member/users"
          element={
            <RequireToken>
              <LayoutAdmin>
                <AdminMasterUsers />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/member/users/:user_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <DetailMasterUsers />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/member/submission"
          element={
            <RequireToken>
              <LayoutAdmin>
                <GoldSubmission />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        {/* trade mission */}
        <Route
          path="admin/trade-mission"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterTradeMission />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        {/* business matching */}
        <Route
          path="admin/business-matching"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterBusinessMatching />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/business-matching/:matching_id/contributors"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterMatchingContributors />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/business-matching/:matching_id/contributors/:corporate_matching_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterCorporateBookings />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        {/* learnings */}
        <Route
          path="admin/free-learnings"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterFreeLearnings />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/free-learnings/participants/:free_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterFreeParticipants />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        <Route
          path="admin/paid-learnings"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterPaidLearnings />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/paid-learnings/participants/:paid_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterPaidParticipants />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        {/* projects */}
        <Route
          path="admin/projects"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterProjects />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/projects/:projects_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <DetailProjects />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/projects/create"
          element={
            <RequireToken>
              <LayoutAdmin>
                <CreateProjects />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/projects/edit/:projects_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <EditProjects />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        {/* news info */}
        <Route
          path="admin/newsinfo"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterNews />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/newsinfo/create"
          element={
            <RequireToken>
              <LayoutAdmin>
                <CreateNews />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/newsinfo/edit/:news_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <EditNews />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/newsinfo/:news_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <Detail />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        <Route
          path="admin/corporate-users"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterCorporate />
              </LayoutAdmin>
            </RequireToken>
          }
        />

        <Route
          path="admin/corporate-users/:corporate_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <DetailMasterCorporate />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/testimonial"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterTestimonial />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        <Route
          path="admin/testimonial/:testimonial_id"
          element={
            <RequireToken>
              <LayoutAdmin>
                <MasterTestimonialDetail />
              </LayoutAdmin>
            </RequireToken>
          }
        />
        {/* admin end */}

        {/* website start */}
        <Route
          path="/"
          element={
            <LayoutContent>
              <HomePage />
            </LayoutContent>
          }
        />
        <Route
          path="user-auth"
          element={
            <TokenIsTrue>
              <UserAuth />
            </TokenIsTrue>
          }
        />
        <Route
          path="/login-admin"
          element={
            <TokenIsTrue>
              <LoginAdmin />
            </TokenIsTrue>
          }
        />
        <Route
          path="about-us"
          element={
            <LayoutContent>
              <AboutUs />
            </LayoutContent>
          }
        />
        <Route
          path="banks"
          element={
            <LayoutContent>
              <BankAccounts />
            </LayoutContent>
          }
        />
        <Route
          path="news"
          element={
            <LayoutContent>
              <News />
            </LayoutContent>
          }
        />
        <Route
          path="news/:news_id"
          element={
            <LayoutContent>
              <DetailNews />
            </LayoutContent>
          }
        />
        <Route
          path="projects"
          element={
            <LayoutContent>
              <Projects />
            </LayoutContent>
          }
        />
        <Route
          path="projects/:projects_id"
          element={
            <LayoutContent>
              <DetailProjectsPage />
            </LayoutContent>
          }
        />
        <Route
          path="trade-mission"
          element={
            <LayoutContent>
              <TradeMissions />
            </LayoutContent>
          }
        />
        <Route
          path="trade-mission/:trade_mission_id"
          element={
            <LayoutContent>
              <DetailTradeMission />
            </LayoutContent>
          }
        />
        <Route
          path="business-matching"
          element={
            <LayoutContent>
              <BusinessMatching />
            </LayoutContent>
          }
        />
        <Route
          path="business-matching/:matching_id"
          element={
            <LayoutContent>
              <DetailBusinessMatching />
            </LayoutContent>
          }
        />
        <Route
          path="business-matching/:matching_id/payment"
          element={
            <LayoutContent>
              <PaymentMatching />
            </LayoutContent>
          }
        />
        <Route
          path="learnings"
          element={
            <LayoutContent>
              <Learnings />
            </LayoutContent>
          }
        />
        <Route
          path="learnings/free/:free_id"
          element={
            <LayoutContent>
              <DetailFreeLearnings />
            </LayoutContent>
          }
        />
        <Route
          path="learnings/free/:free_id/link"
          element={
            <LayoutContent>
              <LinkFreeLearnings />
            </LayoutContent>
          }
        />
        <Route
          path="learnings/paid/:paid_id"
          element={
            <LayoutContent>
              <DetailPaidLearnings />
            </LayoutContent>
          }
        />
        <Route
          path="learnings/paid/:paid_id/payment"
          element={
            <LayoutContent>
              <PaymentLearnings />
            </LayoutContent>
          }
        />
        <Route
          path="partners"
          element={
            <LayoutContent>
              <Partners />
            </LayoutContent>
          }
        />
        <Route
          path="partners/:partners_id"
          element={
            <LayoutContent>
              <DetailPartners />
            </LayoutContent>
          }
        />
        <Route
          path="partners/:partners_id/:product_id"
          element={
            <LayoutContent>
              <DetailProduct />
            </LayoutContent>
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
