import { GetUrl } from "api/GetUrl";
import { useQuery } from "react-query";

export const useMembership = () => {
  return useQuery("get-membership", () => GetUrl("/api/members"), {
    refetchOnWindowFocus: false,
  });
};

export const useMembershipDetail = (id) => {
  return useQuery("get-membership-detail", () => GetUrl(`/api/members/${id}`), {
    enabled: false,
  });
};
