import { useContactUsPagination } from "hook/contactUs-hook/apiCalls";
import { BsWhatsapp } from "react-icons/bs";
import Navigation from "../components/navigation/Navigation";
import Footer from "./Footer";
import "./layout.css";

const LayoutContent = ({ theme, children }) => {
  const { data } = useContactUsPagination({
    dataTable: { page: 1, limit: 100 },
  });

  const handleWa = (param) => {
    if (param[0] === "0") return "62" + param.slice(1, param.length);
    else if (param[0] === "6" && param.charAt(1) === "2") return param;
    else return "62" + param;
  };

  return (
    <div className="dark:bg-white dark:text-black">
      <Navigation theme={theme} />
      <div className="mt-[60px]">{children}</div>

      {data?.data?.data[0]?.wa && (
        <a
          href={`https://wa.me/${handleWa(
            data?.data?.data[0]?.wa
          )}?text=Halo admin${data?.data?.data[0]?.wa}`}
          target="_blank"
          rel="noreferrer"
          className="py-4 font-[500] text-center hover:bg-green-400 duration-200 uppercase mt-10 rounded-md text-gray-100 bg-green-500 fixed bottom-6 right-6 w-[50px] h-[50px] md:w-[60px] md:h-[60px] z-50 flex items-center justify-center"
        >
          <BsWhatsapp className="!text-xl md:!text-3xl" />
        </a>
      )}
      <Footer />
    </div>
  );
};

export default LayoutContent;
