import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AboutHeadline from "./headline/Headline";
import Vision from "./vision/Vision";
import Team from "./team/Team";

const AboutUs = () => {
  return (
    <div>
      <AboutHeadline />
      <Vision />
      <Team />
    </div>
  );
};

export default AboutUs;
