import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchTeam,
  setShowCreateTeam,
} from "features/about-us/aboutus-slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { REACT_APP_HOST_API } = process.env;

// const positions = [
//   { label: "commissioner", value: "commissioner" },
//   { label: "director", value: "director" },
//   { label: "staff", value: "staff" },
// ];

const CreateTeam = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const dispatch = useDispatch();
  const { showCreateTeam } = useSelector((state) => state.about);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    dispatch(setShowCreateTeam(false));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (fileList.length === 0) {
          message.error("Harap input photo!");
          return;
        }

        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + "/api/aboutus/ourteam",
            { ...values, image: fileList[0]?.originFileObj },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          dispatch(setFetchTeam(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showCreateTeam}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={600}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Photo">
          <Upload
            fileList={fileList}
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file.fileList);
            }}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setFileList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
          >
            {fileList?.length > 1
              ? null
              : fileList.length === 0
              ? uploadButton
              : "Ganti"}
          </Upload>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="title"
          label="Position"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="link"
          label="Link LinkedIn"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[
            { required: true },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Masukkan hanya Nomor",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default CreateTeam;
