import { Form, Modal, Radio, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useCallback, useEffect, useState } from "react";

const { REACT_APP_HOST_API: url } = process.env;

const EditPaidParticipants = ({ show, id, onUpdate, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(
        url + `/api/biznids-learning/user-paid/${id}?showLink=1`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      form.setFieldsValue({
        status: data?.data?.status,
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  }, [form, id]);

  useEffect(() => {
    if (show) fetchDetail();
  }, [show, fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        try {
          const { data } = await axios.put(
            url + "/api/biznids-learning/user-paid/" + id,
            { status: values.status },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          onUpdate();
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={show}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      style={{ top: 20 }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="status" label="Status">
          <Radio.Group buttonStyle="solid" optionType="button">
            <Radio value="approve">Terima</Radio>
            <Radio value="rejected">Tolak</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditPaidParticipants;
