import { Divider, Image } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { useTestimonialDetail } from "../../../hook/testimonial-hook/apiCalls";
import "../corporates/detailmastercorporate.css";

const MasterTestimonialDetail = () => {
  const { testimonial_id } = useParams();

  const { data } = useTestimonialDetail(testimonial_id);

  return (
    <div className="detail-corporate">
      <Divider orientationMargin="0" orientation="center">
        <h2>Detail Testimonial</h2>
      </Divider>
      <p>
        <span>Nama:</span> {data?.data?.name}
      </p>
      <p>
        <span>Testimonial:</span> {data?.data?.description}
      </p>
      <Image width={300} src={data?.data?.image} />
    </div>
  );
};

export default MasterTestimonialDetail;
