import { Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import Cookies from "js-cookie";
import { useState } from "react";
import { IoInformationCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

const MemberJoinFreeLearnings = ({ idLearnings, show, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const dataUser =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser"));

  const onSubmit = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${REACT_APP_HOST_API}/api/biznids-learning/guest-free`,
        {
          name: dataUser.username,
          phone: dataUser.phone,
          email: dataUser.email,
          freeWebinarId: idLearnings,
        }
      );
      onCancelModal();
      navigate("link");
    } catch (error) {
      error.response
        ? error.response.data.message === "Gagal join webinar"
          ? setShowModal(true)
          : alert(error.response.data.message)
        : alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    onCancel();
  };

  return (
    <>
      <Modal
        open={show}
        okText="Join"
        cancelText="Cancel"
        onOk={onSubmit}
        onCancel={onCancelModal}
        okButtonProps={{ loading }}
        closable={false}
      >
        <h1 className="text-2xl font-bold mt-4 mb-12 text-center">
          Anda yakin akan mengikuti kelas ini?
        </h1>
      </Modal>

      <Modal
        open={showModal}
        okText="Lihat link"
        cancelText="Close"
        onOk={() => navigate("link")}
        onCancel={() => setShowModal(false)}
        closable={false}
      >
        <IoInformationCircle className="text-4xl text-blue-500" />
        <h1 className="text-2xl font-bold mt-4 mb-12 text-center">
          Anda telah mengikuti kelas tersebut
        </h1>
      </Modal>
    </>
  );
};

export default MemberJoinFreeLearnings;
