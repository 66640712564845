import Container from "components/Container";
import { useAboutTeamPagination } from "hook/about-us-hook/apiCalls";
import { useSocialMediaPagination } from "hook/biznids-hook/apiCalls";
import { useContactUsPagination } from "hook/contactUs-hook/apiCalls";
import React from "react";
import { Link } from "react-router-dom";

const navigations = [
  { link: "/", label: "Home" },
  { link: "/about-us", label: "About Us" },
  { link: "/trade-mission", label: "Trade Mission" },
  { link: "/business-matching", label: "Business Matching" },
  { link: "/learnings", label: "Biznids Learning" },
  { link: "/partners", label: "Partners" },
  { link: "/news", label: "News & Info" },
  { link: "/projects", label: "Projects" },
  { link: "/banks", label: "Bank Accounts" },
];

const Footer = () => {
  const { data } = useAboutTeamPagination({
    dataTable: { page: 1, limit: 100 },
  });

  const { data: dataContact } = useContactUsPagination({
    dataTable: { page: 1, limit: 100 },
  });

  const { data: dataSosmed } = useSocialMediaPagination({
    dataTable: { page: 1, limit: 100 },
  });

  return (
    <footer className="py-[70px] bg-[#0C2D57] border-t">
      <Container>
        <div className="flex flex-col md:flex-row gap-5 md:gap-0 pb-12 mb-8 border-b border-b-customblue2-0 text-gray-200">
          <h2 className="w-full md:w-1/4 uppercase">Biznids</h2>

          <div className="w-full md:w-3/4 flex flex-col md:flex-row justify-start gap-10 md:gap-5 md:justify-evenly">
            <ul className="w-full md:w-2/4 md:pr-4">
              <li className="mb-1 text-gray-300">Contact Us</li>
              <li className="mb-1 text-[0.8em] text-gray-400">
                {dataContact?.data?.data[0]?.wa}
              </li>
              <li className="mb-1 text-[0.8em] text-gray-400">
                {dataContact?.data?.data[0]?.email}
              </li>
              <li className="mb-1 text-[0.8em] text-gray-400">
                {dataContact?.data?.data[0]?.address}
              </li>
            </ul>
            <ul className="w-full md:w-1/4">
              <li className="mb-1 text-gray-300">Navigation</li>
              {navigations.map((x, i) => (
                <li key={i} className="mb-1 text-[0.8em] text-gray-400">
                  <Link to={x.link}>{x.label}</Link>
                </li>
              ))}
            </ul>
            {data && (
              <ul className="w-full md:w-1/4">
                <li className="mb-1 text-gray-300">Team</li>
                {data?.data?.data?.map((x, i) => (
                  <li key={i} className="mb-1 text-[0.8em] text-gray-400">
                    {x.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="flex gap-10 flex-wrap items-end">
          <div className="flex gap-4 flex-wrap">
            {dataSosmed?.data?.data?.map((x) => (
              <a
                key={x.id}
                href={x.link}
                target="_blank"
                rel="noreferrer"
                className="bg-gray-200 p-[3px] border border-customblue1-0 rounded-md"
              >
                <img
                  src={x.logo}
                  alt="logo"
                  width={16}
                  height={16}
                  className="object-cover object-center"
                />
              </a>
            ))}
          </div>
          <p className=" text-[14px] font-[300] text-gray-300">
            &copy; 2024 Biznids All rights reserved.
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
