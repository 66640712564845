import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useMembershipDetail } from "hook/membership-hook/apiCalls";
import React, { useEffect, useState } from "react";

const { REACT_APP_HOST_API: url } = process.env;

const EditMember = ({ id, show, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { data, refetch } = useMembershipDetail(id);

  useEffect(() => {
    if (show) refetch();

    if (id)
      form.setFieldsValue({
        cost: data?.data?.cost,
        benefits: data?.data?.benefits,
      });
  }, [form, data, id, show, refetch]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(url + `/api/members/${id}`, values, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      message.success(data.message);
      form.resetFields();
      onSubmit();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={show}
      okText="Submit"
      onCancel={onCancel}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="cost" label="Cost">
          <InputNumber
            formatter={(value) =>
              `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.List name="benefits">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  // {...(index === 0
                  //   ? formItemLayout
                  //   : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Benefits" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          "Please input benefits's name or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="benefit name"
                      style={{ width: "60%" }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="text-xl ml-2"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default EditMember;
