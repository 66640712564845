import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const TableHeader = ({ title, addBtn = true, onClick, textBtn = "Tambah" }) => {
  return (
    <div className="flex flex-wrap justify-between my-2 md:my-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      {addBtn && (
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={onClick}>
          {textBtn}
        </Button>
      )}
    </div>
  );
};

export default TableHeader;
