import {
  Badge,
  Image,
  Input,
  Popconfirm,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import axios from "axios";
import TableHeader from "components/TableHeader";
import { decryptCookies } from "components/helper/cookieshelper";
import { usePaidParticipants } from "hook/learnings-hook/apiCalls";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import EditPaidParticipants from "./EditPaidParticipants";
import { DeleteApi } from "api/DeleteApi";
import { formatRupiah } from "components/helper/formatRupiah";

const { REACT_APP_HOST_API } = process.env;

const MasterPaidParticipants = () => {
  const [dataId, setDataId] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  const [imgUrl, setImgUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const { paid_id } = useParams();

  const { data, isLoading, refetch } = usePaidParticipants({
    keyword,
    dataTable,
    id: paid_id,
  });

  const onUpdate = async (status, id) => {
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + "/api/biznids-learning/user-paid/" + id,
        { status },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      refetch();
    } catch (error) {
      alert(error.message);
    }
  };

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
    name: x?.user?.username,
    phone: x?.user?.phone,
    email: x?.user?.email,
  }));

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "name" },
    { title: "No Telp", dataIndex: "phone" },
    { title: "Email", dataIndex: "email" },
    {
      title: "Status",
      dataIndex: "status",
      render: (x) => (
        <Badge
          status={
            x === "waiting"
              ? "processing"
              : x === "approve"
              ? "success"
              : "error"
          }
          text={
            x === "waiting"
              ? "menunggu"
              : x === "approve"
              ? "diterima"
              : "ditolak"
          }
        />
      ),
    },
    {
      title: "Jumlah Bayar",
      dataIndex: "code_unique",
      render: (x) => "Rp" + formatRupiah(x),
    },
    {
      title: "Bukti Bayar",
      dataIndex: "image",
      render: (x) => (
        <>
          {x ? (
            <Tag
              color="blue"
              onClick={() => {
                setImgUrl(x);
                setVisible(true);
              }}
              className="cursor-pointer"
            >
              Lihat
            </Tag>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Aksi",
      align: "center",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({
                url: "/api/biznids-learning/user-paid/",
                dataId: record.id,
                refetch,
              })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          {record.status === "waiting" &&
          moment(new Date()).format("YYYY-MM-DD HH:mm:ss") <=
            record.paidWebinar?.from_date ? (
            <Popconfirm
              title="Anda yakin ingin menerima transaksi ini?"
              okText="Terima"
              cancelText="Batal"
              onConfirm={() => onUpdate("approve", record.id)}
            >
              <Tag
                color="green"
                style={{ cursor: "pointer" }}
                // hidden={record.status === "approve"}
              >
                Terima
              </Tag>
            </Popconfirm>
          ) : (
            <Tag
              color="orange"
              style={{ cursor: "pointer" }}
              hidden={record.status === "waiting"}
              onClick={() => {
                setDataId(record.id);
                setShowEdit(true);
              }}
            >
              Edit Status
            </Tag>
          )}
        </Space>
      ),
    },
  ];

  const onCancel = () => setShowEdit(false);

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader
        title={`Peserta ${data?.data?.data[0]?.paidWebinar.title}`}
        addBtn={false}
      />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />

      <Image
        width={200}
        style={{
          display: "none",
        }}
        src={imgUrl}
        preview={{
          visible,
          src: imgUrl,
          onVisibleChange: (value) => setVisible(value),
        }}
      />

      <EditPaidParticipants
        id={dataId}
        show={showEdit}
        onCancel={onCancel}
        onUpdate={refetch}
      />
    </div>
  );
};

export default MasterPaidParticipants;
