import { Form, Input, Modal, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchMission,
  setShowCreateMission,
} from "features/about-us/aboutus-slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const { TextArea } = Input;
const { REACT_APP_HOST_API } = process.env;

const CreateMission = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { showCreateMission } = useSelector((state) => state.about);

  const onCancelModal = () => {
    form.resetFields();
    dispatch(setShowCreateMission(false));
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + "/api/aboutus/misi",
            values,
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          message.success(data.message);
          dispatch(setFetchMission(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showCreateMission}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={600}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Title" rules={[{ min: 3 }]}>
          <Input />
        </Form.Item>
        <Form.Item name="text" label="Mission" rules={[{ min: 3 }]}>
          <TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateMission;
