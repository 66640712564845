import { Button } from "antd";
import { useContactUsPagination } from "hook/contactUs-hook/apiCalls";
import CreateInfo from "./Create";
import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import EditInfo from "./Edit";

const InfoBiznids = () => {
  const [dataId, setDataId] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { data, refetch } = useContactUsPagination({
    dataTable: { page: 1, limit: 100 },
  });

  const onCancel = () => {
    setShowCreate(false);
    setShowEdit(false);
  };

  return (
    <div>
      {data?.data?.data?.length === 0 && (
        <Button type="primary" onClick={() => setShowCreate(true)}>
          Tambah Info Biznids
        </Button>
      )}

      <div>
        <div className="mb-5 flex flex-wrap md:flex-nowrap gap-4 relative">
          <div className="w-full md:w-1/5">
            <img
              src={data?.data?.data[0]?.logo}
              alt="logo"
              width={100}
              height={100}
              className="object-cover object-center"
            />
          </div>
          <table className="w-full md:w-3/5">
            <tbody>
              <tr>
                <td className="p-1 border-b">Whatsapp </td>
                <td className="p-1 border-b">: {data?.data?.data[0]?.wa}</td>
              </tr>
              <tr>
                <td className="p-1 border-b">Email </td>
                <td className="p-1 border-b">: {data?.data?.data[0]?.email}</td>
              </tr>
              <tr>
                <td className="p-1 border-b">Alamat </td>
                <td className="p-1 border-b">
                  : {data?.data?.data[0]?.address}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-full md:w-1/5 flex justify-end">
            <Button
              icon={<EditOutlined />}
              className="border-orange-500 absolute top-2 right-2"
              onClick={() => {
                setDataId(data?.data?.data[0]?.id);
                setShowEdit(true);
              }}
            >
              Edit Info
            </Button>
          </div>
        </div>
      </div>

      <CreateInfo show={showCreate} onCreate={refetch} onCancel={onCancel} />
      <EditInfo
        id={dataId}
        show={showEdit}
        onUpdate={refetch}
        onCancel={onCancel}
      />
    </div>
  );
};

export default InfoBiznids;
