import { useBanksPagination } from "hook/banks-hook/apiCalls";

const Step1 = () => {
  const { data } = useBanksPagination({ dataTable: { page: 1, limit: 100 } });

  return (
    <div>
      <h1 className="text-xl mb-4">
        Silahkan bayar ke salah satu rekening di bawah:
      </h1>
      <ul>
        {data?.data?.data?.map((bank) => (
          <li
            key={bank.id}
            className="mb-6 list-disc ml-6 border-b border-b-gray-300 pb-1"
          >
            <span className="">
              {bank.name} - {bank.no_rek}
            </span>
            <span className="block">an/ {bank.an}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Step1;
