import { CalendarOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { handleLongText } from "components/helper/handleLongText";
import { useFreeLearningsPagination } from "hook/learnings-hook/apiCalls";
import moment from "moment";
import { FaMicrophone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const FreeLearnings = () => {
  const navigate = useNavigate();

  const { data } = useFreeLearningsPagination({
    dataTable: { page: 1, limit: 10000000 },
    upComing: 1,
  });

  return (
    <>
      {data?.data.data.length > 0 ? (
        <div className="flex items-center gap-10 flex-wrap my-10">
          {data?.data?.data?.map((learning, index) => (
            <div key={index}>
              <div
                key={learning.id}
                className="w-[300px] rounded-sm overflow-hidden border border-gray-300 hover:shadow-lg duration-200"
              >
                <img
                  src={learning.banner}
                  alt="learnings"
                  className="w-[100%] h-[150px] object-cover object-center"
                />
                <div className="pt-2 pb-3 px-3">
                  <h2 className="text-customblue2-0 text-[18px] font-[500] my-1">
                    {learning.title}
                  </h2>
                  <p className="font-[300] text-gray-600 text-[15px] mb-4">
                    {handleLongText(learning.description, 60)}
                  </p>

                  <div className="flex items-center justify-between text-[14px] mb-4">
                    <div>
                      <p>Free</p>
                    </div>
                    <div className="font-[300] text-gray-500 text-[13px] flex items-center gap-2">
                      <CalendarOutlined />
                      <p>{moment(learning.from_date).format("YYYY-MM-DD")}</p>
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <p className="font-[300] text-gray-500 text-[13px] flex gap-2 items-center">
                      <FaMicrophone className="text-[16px]" />
                      {learning.speaker}
                    </p>

                    <Button
                      className="border-2 border-orange-500 hover:!border-orange-500 hover:bg-orange-500 hover:!text-white"
                      onClick={() => navigate(`/learnings/free/${learning.id}`)}
                    >
                      Read more
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Empty description="No Course" className="w-[200px] my-20" />
      )}
    </>
  );
};

export default FreeLearnings;
