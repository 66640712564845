import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useCallback, useEffect, useState } from "react";

const { TextArea } = Input;

const { REACT_APP_HOST_API: url } = process.env;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditCorporate = ({ show, onCancel, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [newFileList, setNewFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const userId = JSON.parse(decryptCookies("dataUser")).id;

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(url + `/api/users/${userId}`, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      if (data?.data?.image) {
        setFileList([
          {
            uid: "-1",
            name: "profile",
            status: "done",
            url: data.data.image,
          },
        ]);
      }

      form.setFieldsValue({
        username: data?.data?.username,
        companyName: data?.data?.company_name,
        url: data?.data?.url,
        phone: data?.data?.phone,
        description: data?.data?.description,
      });
    } catch (error) {
      alert("Error: " + error.message);
    }
  }, [form, userId]);

  useEffect(() => {
    if (show) fetchDetail();
  }, [show, fetchDetail]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(
        url + `/api/users/${userId}`,
        { ...values, image: newFileList ? newFileList[0] : fileList[0] },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data.message);
      form.resetFields();
      onUpdate();
      onCancel();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <>
      <Modal
        open={show}
        okText="Submit"
        onCancel={onCancelModal}
        onOk={handleSubmit}
        okButtonProps={{ loading }}
        width={800}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Gambar">
            <Upload
              fileList={fileList}
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              onRemove={() => false}
              onChange={(file) => {
                if (file?.file.size > 3000000) return;
                else setFileList(file.fileList);
              }}
              beforeUpload={(file) => {
                if (file.size <= 3000000) {
                  setFileList([file]);
                  setNewFileList([file]);
                  return false;
                }
                if (file.size > 3000000) {
                  message.error("Maks File 3MB");
                }
              }}
            >
              {fileList?.length > 1
                ? null
                : fileList.length === 0
                ? uploadButton
                : "Ganti"}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ min: 3, message: "Input minimum 3 character" }]}
            className="w-full"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ min: 3, message: "Input minimum 3 character" }]}
            className="w-full"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Link Url (web/ marketplace/ etc...)"
            name="url"
            rules={[{ min: 3, message: "Input minimum 3 character" }]}
            className="w-full"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Input only number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Company Description">
            <TextArea rows={10} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default EditCorporate;
