import { CalendarOutlined } from "@ant-design/icons";
import { Button, Empty, Pagination } from "antd";
import Container from "components/Container";
import { useNavigate } from "react-router-dom";

const ProjectsList = ({
  dataProjects,
  onShowSizeChange,
  handleChange,
  dataTable,
}) => {
  const navigate = useNavigate();

  return (
    <Container className="my-20">
      <div className="w-[90%] mx-auto">
        <div className="flex flex-wrap mb-8">
          <h2 className="text-2xl font-bold mr-8">All Projects</h2>
        </div>

        {dataProjects?.data.data.length > 0 ? (
          <div className="flex items-center gap-10 flex-wrap">
            {dataProjects?.data?.data?.map((projects, index) => (
              <div key={index}>
                <div
                  key={projects.id}
                  className="w-[300px] rounded-sm overflow-hidden border border-gray-300 hover:shadow-lg duration-200"
                >
                  <img
                    src={projects.banner}
                    alt="event"
                    className="w-[100%] h-[150px] object-cover object-center"
                  />
                  <div className="pt-2 pb-3 px-3">
                    <h2 className="text-customblue2-0 text-[18px] font-[500] my-1">
                      {projects.title}
                    </h2>
                    <div className="flex justify-between items-center mt-7">
                      <p className="font-[300] text-gray-500 text-[13px] flex gap-2 items-center">
                        <CalendarOutlined /> {projects.date}
                      </p>

                      <Button
                        className="border-2 border-orange-500 hover:!border-orange-500 hover:bg-orange-500 hover:!text-white"
                        onClick={() => navigate(`/projects/${projects.id}`)}
                      >
                        Read more
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty description="No Projects" className="w-[200px] mt-20" />
        )}

        <div
          className={`flex justify-end mt-10 ${
            dataProjects?.data?.data?.length > 0 ? "block" : "hidden"
          }`}
        >
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={dataTable.page}
            defaultPageSize={100}
            total={dataProjects?.data?.meta?.total}
            onChange={handleChange}
          />
        </div>
      </div>
    </Container>
  );
};

export default ProjectsList;
