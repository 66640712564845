import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyword: "",
  newsId: "",
  newsHeadlineId: "",
  showCreateNewsHeadline: false,
  showEditNewsHeadline: false,
  fetchNews: false,
  fetchNewsHeadline: false,
};

const newsSlice = createSlice({
  name: "news-info",
  initialState,
  reducers: {
    setNewsId: (state, action) => {
      state.newsId = action.payload;
    },
    setNewsHeadlineId: (state, action) => {
      state.newsHeadlineId = action.payload;
    },
    setShowCreateNewsHeadline: (state, action) => {
      state.showCreateNewsHeadline = action.payload;
    },
    setShowEditNewsHeadline: (state, action) => {
      state.showEditNewsHeadline = action.payload;
    },
    setFetchNews: (state, action) => {
      state.fetchNews = action.payload;
    },
    setFetchNewsHeadline: (state, action) => {
      state.fetchNewsHeadline = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
});

export const {
    setNewsId,
  setShowCreateNewsHeadline,
  setFetchNews,
  setKeyword,
  setShowEditNewsHeadline,
  setFetchNewsHeadline,
  setNewsHeadlineId,
} = newsSlice.actions;
export default newsSlice.reducer;
