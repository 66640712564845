import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Radio, Upload, message } from "antd";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { decryptCookies } from "../../../components/helper/cookieshelper";
import { CorporateContext } from "../../../context/CorporateContext";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditCorporate = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newFileList, setNewFileList] = useState([]);

  const { corporateId, showEdit, setShowEdit, setFetchCorporate } =
    useContext(CorporateContext);

  const { REACT_APP_HOST_API } = process.env;

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/api/users/${corporateId}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      if (data?.data?.image) {
        setFileList([
          {
            uid: "-1",
            name: "corporate",
            status: "done",
            url: data.data.image,
          },
        ]);
      }

      form.setFieldsValue({
        username: data?.data?.username,
        companyName: data?.data?.company_name,
        phone: data?.data?.phone,
        url: data?.data?.url,
        showOnPartners: data?.data?.show_on_partners,
        showOnHome: data?.data?.show_on_home,
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  }, [form, REACT_APP_HOST_API, corporateId]);

  useEffect(() => {
    if (showEdit) fetchDetail();

    return () => {
      setNewData({});
      setFileList([]);
      setNewFileList([]);
    };
  }, [showEdit, fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    setNewFileList([]);
    setShowEdit(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = (param) =>
    (newData[param.target.name] = param.target.value);

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (Object.keys(newData).length === 0 && newFileList.length === 0) {
          alert("Tidak ada yang dirubah");
          return;
        }

        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + "/api/users/" + corporateId,
            newFileList.length > 0
              ? {
                  ...values,
                  image: newFileList[0],
                }
              : newData,
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          setFetchCorporate(true);
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showEdit}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="username" label="Username" rules={[{ min: 3 }]}>
          <Input name="username" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="Company Name"
          name="companyName"
          rules={[{ min: 3, message: "Input minimum 3 character" }]}
        >
          <Input name="companyName" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="No Handphone"
          rules={[
            { min: 7 },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Allow only numbers!",
            },
          ]}
        >
          <Input name="phone" onChange={handleChange} />
        </Form.Item>

        <div className="flex flex-col md:flex-row">
          <Form.Item name="showOnPartners" label="Tampil di Partner">
            <Radio.Group name="showOnPartners" onChange={handleChange}>
              <Radio value={true}>Tampil</Radio>
              <Radio value={false}>Tidak</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="showOnHome" label="Tampil di Homepage">
            <Radio.Group name="showOnHome" onChange={handleChange}>
              <Radio value={true}>Tampil</Radio>
              <Radio value={false}>Tidak</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item
          name="url"
          label="Link Perusahaan (web/sosmed)"
          rules={[{ min: 3 }]}
        >
          <Input name="url" onChange={handleChange} />
        </Form.Item>
      </Form>
      <Form.Item label="Image">
        <Upload
          fileList={fileList}
          accept=".jpg,.jpeg,.png"
          listType="picture-card"
          onPreview={handlePreview}
          onRemove={() => false}
          onChange={(file) => {
            if (file?.file.size > 2000000) return;
            else setFileList(file.fileList);
          }}
          beforeUpload={(file) => {
            if (file.size <= 2000000) {
              setFileList([file]);
              setNewFileList([file]);
              return false;
            }
            if (file.size > 2000000) {
              message.error("Maks File 2MB");
            }
          }}
        >
          {fileList?.length > 1
            ? null
            : fileList.length === 0
            ? uploadButton
            : "Ganti"}
        </Upload>
      </Form.Item>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default EditCorporate;
