import { EditOutlined } from "@ant-design/icons";
import { Button, Image, Skeleton } from "antd";
import { decryptCookies } from "components/helper/cookieshelper";
import { useUsersDetail } from "hook/users-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import EditUser from "./Edit";
import EditUserPassword from "./EditPassword";

const DashboardCoporate = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);

  const corporateId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const { data, isLoading, refetch } = useUsersDetail(corporateId);

  useEffect(() => {
    if (corporateId) refetch();
  }, [refetch, corporateId]);

  const onCancel = () => {
    setShowEdit(false);
    setShowEditPassword(false);
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="flex flex-col lg:flex-row gap-10 relative h-auto">
              <Image
                width={200}
                src={data?.data?.image}
                className="rounded-md border"
              />
              <table className="w-full max-w-[500px]">
                <tbody>
                  <tr className="border-b">
                    <td className="w-1/2 py-2">Username</td>
                    <td className="w-1/2 py-2">{data?.data?.username}</td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/2 py-2">Company</td>
                    <td className="w-1/2 py-2">{data?.data?.company_name}</td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/2 py-2">Phone</td>
                    <td className="w-1/2 py-2">{data?.data?.phone}</td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/2 py-2">Url Link</td>
                    <td className="w-1/2 py-2">{data?.data?.url}</td>
                  </tr>
                </tbody>
              </table>
              <div className="absolute top-1 right-1 flex flex-col items-end">
                <Button
                  size="middle"
                  type="primary"
                  className="flex items-center mb-2"
                  onClick={() => setShowEdit(true)}
                >
                  <EditOutlined />
                  Edit Profile
                </Button>
                <Button
                  size="middle"
                  className="flex items-center mb-2"
                  onClick={() => setShowEditPassword(true)}
                >
                  <EditOutlined />
                  Reset Password
                </Button>
              </div>
            </div>
            <p className="my-6 indent-6">{data?.data?.description}</p>
          </>
        )}
      </div>
      <EditUser show={showEdit} onCancel={onCancel} onUpdate={refetch} />
      <EditUserPassword
        show={showEditPassword}
        onCancel={onCancel}
        onUpdate={refetch}
      />
    </>
  );
};

export default DashboardCoporate;
