import { Input, Table } from "antd";
import TableHeader from "components/TableHeader";
import { useFreeParticipants } from "hook/learnings-hook/apiCalls";
import { useState } from "react";
import { useParams } from "react-router-dom";

const MasterFreeParticipants = () => {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const { free_id } = useParams();

  const { data, isLoading } = useFreeParticipants({
    keyword,
    dataTable,
    id: free_id,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "name" },
    { title: "No Telp", dataIndex: "phone" },
    { title: "Email", dataIndex: "email" },
    // {
    //   title: "Aksi",
    //   dataIndex: "id",
    //   align: "center",
    //   render: (id) => (
    //     <Space>
    //       <Popconfirm
    //         title="Yakin ingin menghapus?"
    //         okText="Hapus"
    //         cancelText="Batal"
    //         onConfirm={() =>
    //           DeleteApi({
    //             url: "/api/biznids-learning/free/",
    //             dataId: id,
    //             refetch,
    //           })
    //         }
    //       >
    //         <Tag color="magenta" style={{ cursor: "pointer" }}>
    //           Hapus
    //         </Tag>
    //       </Popconfirm>
    //       <Tag
    //         color="orange"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => {
    //           dispatch(setShowEditFreeWebinars(true));
    //           dispatch(setFreeWebinarsId(id));
    //         }}
    //       >
    //         Ubah
    //       </Tag>
    //       <Tag
    //         color="blue"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => navigate(`peserta/${id}`)}
    //       >
    //         Peserta
    //       </Tag>
    //     </Space>
    //   ),
    // },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader
        title={`Peserta ${data?.freeWebinar?.title}`}
        addBtn={false}
      />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />
    </div>
  );
};

export default MasterFreeParticipants;
