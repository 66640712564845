import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamId: "",
  visionId: "",
  missionId: "",
  aboutHeadlineId: "",
  showCreateAboutHeadline: false,
  showCreateVision: false,
  showCreateMission: false,
  showCreateTeam: false,
  showEditVision: false,
  showEditMission: false,
  showEditTeam: false,
  showEditAboutHeadline: false,
  fetchAboutHeadline: false,
  fetchVision: false,
  fetchMission: false,
  fetchTeam: false,
};

const aboutusSlice = createSlice({
  name: "about-us",
  initialState,
  reducers: {
    setAboutHeadlineId: (state, action) => {
      state.aboutHeadlineId = action.payload;
    },
    setShowCreateAboutHeadline: (state, action) => {
      state.showCreateAboutHeadline = action.payload;
    },
    setShowEditAboutHeadline: (state, action) => {
      state.showEditAboutHeadline = action.payload;
    },
    setFetchAboutHeadline: (state, action) => {
      state.fetchAboutHeadline = action.payload;
    },

    setVisionId: (state, action) => {
      state.visionId = action.payload;
    },
    setShowCreateVision: (state, action) => {
      state.showCreateVision = action.payload;
    },
    setShowEditVision: (state, action) => {
      state.showEditVision = action.payload;
    },
    setFetchVision: (state, action) => {
      state.fetchVision = action.payload;
    },

    setMissionId: (state, action) => {
      state.missionId = action.payload;
    },
    setShowCreateMission: (state, action) => {
      state.showCreateMission = action.payload;
    },
    setShowEditMission: (state, action) => {
      state.showEditMission = action.payload;
    },
    setFetchMission: (state, action) => {
      state.fetchMission = action.payload;
    },

    setTeamId: (state, action) => {
      state.teamId = action.payload;
    },
    setShowCreateTeam: (state, action) => {
      state.showCreateTeam = action.payload;
    },
    setShowEditTeam: (state, action) => {
      state.showEditTeam = action.payload;
    },
    setFetchTeam: (state, action) => {
      state.fetchTeam = action.payload;
    },
  },
});

export const {
  setAboutHeadlineId,
  setShowCreateAboutHeadline,
  setFetchAboutHeadline,
  setShowEditAboutHeadline,
  setFetchVision,
  setShowCreateVision,
  setShowEditVision,
  setVisionId,
  setFetchMission,
  setMissionId,
  setShowCreateMission,
  setShowEditMission,
  setFetchTeam,
  setShowCreateTeam,
  setShowEditTeam,
  setTeamId,
} = aboutusSlice.actions;
export default aboutusSlice.reducer;
