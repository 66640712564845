import { createContext, useState } from "react";

export const TestimonialContext = createContext();

export const TestimonialProvider = ({ children }) => {
  const [testimonialId, setTestimonialId] = useState("");
  const [fetchTestimonial, setFetchTestimonial] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <TestimonialContext.Provider
      value={{
        testimonialId,
        fetchTestimonial,
        showCreate,
        showEdit,
        setTestimonialId,
        setFetchTestimonial,
        setShowCreate,
        setShowEdit,
      }}
    >
      {children}
    </TestimonialContext.Provider>
  );
};
