import { PlusOutlined } from "@ant-design/icons";
import RichTextEditor from "@mantine/rte";
import { Button, DatePicker, Form, Input, Modal, Upload, message } from "antd";
import axios from "axios";
import Loading from "components/Loading";
import { decryptCookies } from "components/helper/cookieshelper";
import dayjs from "dayjs";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const date = "YYYY-MM-DD";
const { REACT_APP_HOST_API } = process.env;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditProjects = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newFileList, setNewFileList] = useState([]);
  const [newBannerList, setNewBannerList] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [value, setValue] = useState("");

  const { projects_id } = useParams();
  const navigate = useNavigate();
  const html2json = require("html2json").html2json;
  const json2html = require("html2json").json2html;

  const fetchDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/api/project-contents/${projects_id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      if (data?.data?.banner) {
        const image = data.data.banner;
        const newImage = {
          uid: 1,
          name: image.split("projects")[1].split("?")[0].substring(1),
          status: "done",
          url: image,
        };

        setBannerList([newImage]);
      }

      if (data?.data?.images) {
        const newImages = data.data.images.map((x, i) => {
          return {
            uid: i + 1,
            name: x.split("projects")[1].split("?")[0].substring(1),
            status: "done",
            url: x,
          };
        });

        setFileList(newImages);
      }

      setValue(json2html(data?.data?.content));
      form.setFieldsValue({
        title: data?.data?.title,
        responsibility: data?.data?.responsibility,
        date: dayjs(moment(data?.data?.date).format(date)),
        content: json2html(data?.data?.content),
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }, [form, projects_id, json2html]);

  useEffect(() => {
    fetchDetail();

    return () => {
      setDeletedImages([]);
      setNewFileList([]);
    };
  }, [fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    setBannerList([]);
    setNewBannerList([]);
    setNewFileList([]);
    setDeletedImages([]);
    navigate(-1);
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/api/project-contents/${projects_id}`,
        newFileList.length === 0 && deletedImages === 0 && newBannerList === 0
          ? {
              ...values,
              content: html2json(value),
              date: values?.date?.format(date),
            }
          : {
              ...values,
              content: html2json(value),
              date: values?.date?.format(date),
              images: newFileList?.map((x) => x),
              banner: newBannerList[0],
              deleteImages: deletedImages.length > 0 && deletedImages.join(","),
            },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success(data.message);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  // image upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Form form={form} layout="vertical">
        <Form.Item label="Gambar Thumbnail">
          <Upload
            fileList={bannerList}
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setBannerList(file.fileList);
            }}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setBannerList([file]);
                setNewBannerList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
          >
            {bannerList?.length > 1
              ? null
              : bannerList.length === 0
              ? uploadButton
              : "Ganti"}
          </Upload>
        </Form.Item>
        <Form.Item label="Gambar Produk">
          <Upload
            fileList={fileList}
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file.fileList);
            }}
            onRemove={(file) => {
              setDeletedImages([...deletedImages, file?.name]);
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
            }}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setFileList([...fileList, file]);
                setNewFileList([...newFileList, file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 2MB");
              }
            }}
          >
            {fileList?.length > 6 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item name="title" label="Nama Project" rules={[{ min: 3 }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="responsibility"
          label="Penanggung Jawab/ Ketua Project"
          rules={[{ min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="date" label="Tanggal">
          <DatePicker />
        </Form.Item>

        <Form.Item name="content" label="Catatan" rules={[{ min: 3 }]}>
          <RichTextEditor
            value={value}
            onChange={setValue}
            id="rte"
            controls={[
              ["bold", "italic", "underline", "link"],
              ["h1", "h2", "h3"],
              ["unorderedList", "orderedList"],
              ["alignLeft", "alignCenter", "alignRight"],
            ]}
          />
        </Form.Item>

        <div className="my-4 flex justify-end gap-4">
          <Button onClick={onCancelModal}>Batal</Button>
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Submit
          </Button>
        </div>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default EditProjects;
