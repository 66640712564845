import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  token: Cookies.get("token"),
  authContent: "sign-in",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthContent: (state, action) => {
      state.authContent = action.payload;
    },
  },
});

export const { setToken, setAuthContent } = authSlice.actions;
export default authSlice.reducer;
