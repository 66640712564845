import { Image } from "antd";
import Container from "components/Container";
import { useProductsDetail } from "hook/products-hook/apiCalls";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const DetailProduct = () => {
  const { product_id } = useParams();

  const { data, refetch } = useProductsDetail({ id: product_id });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Container className="py-20">
      <div className="flex flex-col lg:flex-row gap-4 max-w-screen-lg mx-auto">
        <div className="w-full lg:w-[40%]">
          <img
            src={data?.data?.banner}
            alt="product"
            className="w-[300px] lg:w-full h-[300px] lg:h-[350px] object-cover object-center rounded-xl"
          />
        </div>

        <div className="w-full lg:w-[60%] lg:ml-3">
          <h1 className="text-2xl lg:text-3xl font-[500] mb-4">
            {data?.data?.name}
          </h1>
          <p className="text-[14px]">
            Link Product:{" "}
            <a
              href={data?.data?.link}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              {data?.data?.link}
            </a>
          </p>
          <h2 className="text-xl lg:text-2xl font-[500]">
            Rp
            {data?.data?.price
              ? data?.data?.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              : ""}
          </h2>
          <h3 className="mt-8 font-[500]">Description</h3>
          <p className="text-gray-600">{data?.data?.description}</p>
        </div>
      </div>

      <div className="flex flex-wrap gap-5 max-w-screen-lg mx-auto mt-20">
        {data?.data?.images?.map((image, index) => (
          <Image
            src={image}
            key={index}
            width={180}
            height={200}
            className="object-cover object-center rounded-md"
          />
        ))}
      </div>
    </Container>
  );
};

export default DetailProduct;
