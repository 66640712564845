import { ConfigProvider } from "antd";
import Loading from "components/Loading";
import { HeadlinesProvider } from "context/HeadlinesContext";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { store } from "store/store";
import "./App.css";
import { CorporateProvider } from "./context/CorporateContext";
import { TestimonialProvider } from "./context/TestimonialContext";
import RoutesComponent from "./routes/RoutesComponent";

const queryClient = new QueryClient();

function App() {
  return (
    <ConfigProvider
      theme={{ token: { borderRadius: 5, fontFamily: "Onest, sans-serif" } }}
    >
      <QueryClientProvider client={queryClient}>
        <CorporateProvider>
          <TestimonialProvider>
            <HeadlinesProvider>
              <Provider store={store}>
                <Suspense fallback={<Loading />}>
                  <RoutesComponent />
                </Suspense>
              </Provider>
            </HeadlinesProvider>
          </TestimonialProvider>
        </CorporateProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
