import { Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

const MemberJoinPaidLearnings = ({ idLearnings, show, onCancel }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const onSubmit = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${REACT_APP_HOST_API}/api/biznids-learning/user-paid`,
        { userId, paidWebinarId: idLearnings },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      onCancelModal();
      navigate("payment");
    } catch (error) {
      error.response
        ? alert(error.response.data.message)
        : alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    onCancel();
  };

  return (
    <Modal
      open={show}
      okText="Join"
      cancelText="Cancel"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      closable={false}
    >
      <h1 className="text-2xl font-bold mt-4 mb-12 text-center">
        Anda yakin akan mengikuti kelas ini?
      </h1>
    </Modal>
  );
};

export default MemberJoinPaidLearnings;
