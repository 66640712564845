import {
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchMatchingHeadline,
  setKeyword,
  setMatchingHeadlineId,
  setShowCreateHeadline,
  setShowEditHeadline,
} from "features/business-matching/business-matching-slice";
import { useMatchingHeadline } from "hook/business-matching-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditMatchingHeadline from "./Edit";
import CreateMatchingHeadline from "./Create";

const Headline = () => {
  const dispatch = useDispatch();
  const { fetchMatchingHeadline } = useSelector(
    (state) => state.businessMatching
  );

  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;

  const { data, refetch } = useMatchingHeadline();

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchMatchingHeadline(false));
  }, [refetch, dispatch, fetchMatchingHeadline]);

  return (
    <div className="relative h-[calc(100vh-60px)] after:absolute after:block after:w-full after:min-h-screen after:md:min-h-[calc(100vh-60px)] after:md:h-[calc(100vh-60px)] after:left-0 after:top-0 after:z-20 after:bg-gradient-to-r after:from-[rgba(0,0,0,0.9)] after:to-[rgba(0,0,0,0.3)] overflow-hidden">
      {data?.data?.data?.length > 0 ? (
        <>
          <img
            src={data?.data?.data[0]?.banner}
            alt="event"
            className="object-cover object-center rounded-sm absolute top-0 left-0 w-full h-full z-10"
          />
          <div className="absolute top-[50%] translate-y-[-50%] left-4 right-4 md:left-10 z-30 max-w-[900px]">
            <h1 className="text-gray-100 text-4xl md:text-5xl mb-2">
              {data?.data?.data[0]?.title}
            </h1>
            <p className="text-gray-200">{data?.data?.data[0]?.description}</p>
          </div>
          <div className="absolute bottom-8 left-[50%] translate-x-[-50%] z-30 w-[95%]">
            <Input
              className="rounded-lg h-[50px]"
              prefix={<SearchOutlined className="text-xl mr-4" />}
              placeholder="Search event..."
              onChange={({ target: { value } }) => dispatch(setKeyword(value))}
            />
          </div>
          {role === "admin_user" && (
            <div
              className="absolute top-[20px] right-10 z-30 max-w-[500px] text-xl text-white flex gap-3 drop-shadow-xl cursor-pointer font-[300]"
              onClick={() => {
                if (role === "admin_user") {
                  dispatch(setMatchingHeadlineId(data?.data?.data[0]?.id));
                  dispatch(setShowEditHeadline(true));
                } else return;
              }}
            >
              <EditOutlined />
              <span>Edit Headline</span>
            </div>
          )}
        </>
      ) : (
        role === "admin_user" && (
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            disabled={role !== "admin_user"}
            onClick={() => {
              if (role === "admin_user") dispatch(setShowCreateHeadline(true));
              else return;
            }}
            className="absolute top-[20px] right-10 z-30 flex items-center drop-shadow-xl font-[300]"
          >
            Add Headline
          </Button>
        )
      )}

      <CreateMatchingHeadline />
      <EditMatchingHeadline />
    </div>
  );
};

export default Headline;
