import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Radio, Upload, message } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import { decryptCookies } from "../../../components/helper/cookieshelper";
import { TestimonialContext } from "../../../context/TestimonialContext";

const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const CreateTestimonial = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const { showCreate, setShowCreate, setFetchTestimonial } =
    useContext(TestimonialContext);

  const { REACT_APP_HOST_API } = process.env;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (fileList.length === 0)
          return message.error("Harap tambahkan gambar");

        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + "/api/testimonis",
            { ...values, image: fileList[0]?.originFileObj },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          setFetchTestimonial(true);
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  const onCancelModal = () => {
    setShowCreate(false);
    form.resetFields();
    setFileList([]);
  };

  return (
    <Modal
      open={showCreate}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nama"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="position"
          label="Pekerjaan(Posisi)"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Testimonial"
          rules={[{ required: true }, { min: 7 }]}
        >
          <TextArea rows={8} />
        </Form.Item>
        <Form.Item label="Photo">
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
            onRemove={(file) => {
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
            }}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file?.fileList);
            }}
            fileList={fileList}
          >
            {fileList.length > 0 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[
            { required: true },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Masukkan hanya Nomor",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="showOnHome"
          label="Tampil di Homepage"
          initialValue={true}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>tampil</Radio>
            <Radio value={false}>tidak</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default CreateTestimonial;
