import { Form, Input, Modal, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { setFetchUser, setShowEdit } from "features/member/userMember-slice";
import { useUsersDetail } from "hook/users-hook/apiCalls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const { REACT_APP_HOST_API: url } = process.env;

const AdminEditUser = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { userId, showEdit } = useSelector((state) => state.userMember);

  const { data, refetch } = useUsersDetail(userId);

  useEffect(() => {
    if (showEdit && data && data.data) {
      form.setFieldsValue({
        username: data.data.username,
        companyName: data.data.company_name,
        phone: data.data.phone,
      });
    }
  }, [form, data, showEdit]);

  useEffect(() => {
    if (showEdit) refetch();
  }, [showEdit, refetch]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(url + `/api/users/${userId}`, values, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      message.success(data.message);
      form.resetFields();
      dispatch(setShowEdit(false));
      dispatch(setFetchUser(true));
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={showEdit}
      okText="Submit"
      onCancel={() => dispatch(setShowEdit(false))}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Username"
          name="username"
          rules={[{ min: 3, message: "Input minimum 3 character" }]}
          className="w-full"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Company Name"
          name="companyName"
          rules={[{ min: 3, message: "Input minimum 3 character" }]}
          className="w-full"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { pattern: new RegExp("^([0-9]*)$"), message: "Input only number" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminEditUser;
