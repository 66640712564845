import { GetUrl } from "api/GetUrl";
import { useQuery } from "react-query";

export const useProductsPagination = ({ dataTable, keyword = "", userId }) => {
  return useQuery(
    [
      "get-products-pagination",
      dataTable.page,
      dataTable.limit,
      keyword,
      userId,
    ],
    () =>
      GetUrl(
        `/api/products?page=${dataTable.page}&limit=${dataTable.limit}&userId=${userId}&keyword=${keyword}`
      )
  );
};

export const useProductsDetail = ({ id }) => {
  return useQuery(
    ["get-products-detail", id],
    () => GetUrl(`/api/products/${id}`),
    { refetchOnWindowFocus: true, enabled: false }
  );
};
