import { useTradeMissionDetail } from "hook/trade-mission-hook/apiCalls";
import Headline from "./Headline";
import Info from "./Info";
import Overview from "./Overview";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const DetailTradeMission = () => {
  const { trade_mission_id } = useParams();

  const { data, refetch } = useTradeMissionDetail(trade_mission_id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <Headline data={data} />
      <Overview data={data} />
      <Info data={data} />
    </div>
  );
};

export default DetailTradeMission;
