import { Form, Modal, Radio, Space } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import Cookies from "js-cookie";
import moment from "moment";
import momentTz from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

const FormBooking = ({
  idCorporate,
  show,
  onCancel,
  onSuccess,
  intervals,
  dates,
}) => {
  const [form] = Form.useForm();
  const [date, setDate] = useState("");
  const [times, setTimes] = useState([]);
  const [dataBook, setDataBook] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  // Fetch corporate event details
  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${REACT_APP_HOST_API}/api/event-offline/corporates/${idCorporate}`
      );
      setDataBook(data.data?.userBookEventOffline);
    } catch (error) {
      error.response
        ? alert(error.response.data.message)
        : alert(error.message);
    }
  }, [idCorporate]);

  // Fetch details when the modal is shown
  useEffect(() => {
    if (show) fetchDetail();
  }, [show, fetchDetail]);

  // Update available times when the date changes
  useEffect(() => {
    const clientTimeZone = momentTz.tz.guess();
    const filteredData = dataBook.filter((book) => book.date === date);

    const result = intervals.filter(
      (interval) =>
        !filteredData.some(
          (book) =>
            book.from_time ===
            momentTz.tz(interval, "HH:mm:ss", clientTimeZone).format("HH:mm:ss")
        )
    );

    const filteredTime = result.map((element) => {
      const fromTime = momentTz
        .tz(element, "HH:mm:ss", clientTimeZone)
        .format("HH:mm:ss");
      // const toTime = result[index + 1]
      // 	? momentTz
      // 			.tz(result[index + 1], 'HH:mm:ss', clientTimeZone)
      // 			.format('HH:mm:ss')
      // 	: momentTz
      // 			.tz(element, 'HH:mm:ss', clientTimeZone)
      // 			.add(30, 'minutes')
      // 			.format('HH:mm:ss');

      const toTime = momentTz
        .tz(fromTime, "HH:mm:ss", clientTimeZone)
        .add(30, "minutes")
        .format("HH:mm:ss");

      return {
        label: `${momentTz
          .tz(fromTime, "HH:mm:ss", clientTimeZone)
          .format("HH:mm")} - ${momentTz
          .tz(toTime, "HH:mm:ss", clientTimeZone)
          .format("HH:mm")}`,
        value: `${fromTime} - ${toTime}`,
      };
    });

    setTimes(filteredTime);
  }, [date, dataBook, intervals]);

  // Form submission
  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      const [fromTime, toTime] = values.time.split("-");

      setLoading(true);
      await axios.post(
        `${REACT_APP_HOST_API}/api/event-offline/corporate/users`,
        {
          date: values.date,
          fromTime: fromTime.trim(),
          toTime: toTime.trim(),
          userId,
          corporateEventOfflineId: idCorporate,
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      onSuccess();
      onCancelModal();
      navigate("payment");
    } catch (error) {
      error.response
        ? alert(error.response.data.message)
        : error.message
        ? alert(error.message)
        : alert("Masukkan Tanggal dan Jam!");
    } finally {
      setLoading(false);
    }
  };

  // Modal cancel
  const onCancelModal = () => {
    onCancel();
    setDate("");
    form.resetFields();
  };

  return (
    <Modal
      open={show}
      okText="Submit"
      cancelText="Cancel"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={1000}
    >
      <Form form={form} layout="vertical">
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <Form.Item
            name="date"
            label="Pilih Tanggal"
            rules={[{ required: true, message: "Please select a date" }]}
          >
            <Radio.Group onChange={(e) => setDate(e.target.value)}>
              <Space direction="vertical">
                {dates?.map((x, i) => (
                  <Radio key={i} value={x}>
                    {moment(x).format("MMMM DD, YYYY")}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          {date !== "" && times.length !== 0 && (
            <Form.Item
              name="time"
              label="Waktu yang tersedia"
              rules={[{ required: true, message: "Please select a time" }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  {times?.map((x, i) => (
                    <Radio key={i} value={x.value}>
                      {x.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
          {times.length === 0 && (
            <p className="text-[16px] text-red-600">
              Maaf untuk tanggal tersebut sudah terbooking semua
            </p>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default FormBooking;
