import { Input, Popconfirm, Space, Table, Tag } from "antd";
import TableHeader from "components/TableHeader";
import { HeadlinesContext } from "context/HeadlinesContext";
import { useHeadlinesPagination } from "hook/headlines-hook/apiCalls";
import { useContext, useEffect, useState } from "react";
import { DeleteApi } from "../../../api/DeleteApi";
import CreateHeadlines from "./Create";
import EditHeadlines from "./Edit";
import DetailHeadlines from "./Detail";

const MasterHeadlines = () => {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const {
    setShowCreate,
    setShowEdit,
    fetchHeadlines,
    setFetchHeadlines,
    setHeadlinesId,
    setShowDetail,
  } = useContext(HeadlinesContext);

  const { data, isLoading, refetch } = useHeadlinesPagination({
    keyword,
    dataTable,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  useEffect(() => {
    refetch();

    return () => setFetchHeadlines(false);
  }, [refetch, fetchHeadlines, setFetchHeadlines]);

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Judul", dataIndex: "title" },
    {
      title: "Tampil di Home",
      dataIndex: "show_on_home",
      render: (x) => (x === true ? "Tampil" : "Tidak"),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({ url: "/api/headlines/", dataId: id, refetch })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowEdit(true);
              setHeadlinesId(id);
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowDetail(true);
              setHeadlinesId(id);
            }}
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };

  return (
    <div>
      <TableHeader title="Headlines" onClick={() => setShowCreate(true)} />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />
      <CreateHeadlines />
      <EditHeadlines />
      <DetailHeadlines />
    </div>
  );
};

export default MasterHeadlines;
