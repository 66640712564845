import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Image,
  Modal,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Upload,
  message,
} from "antd";
import axios from "axios";
import DividerComponent from "components/Divider";
import {
  decryptCookies,
  encryptCookies,
} from "components/helper/cookieshelper";
import { useUsersDetail } from "hook/users-hook/apiCalls";
import Cookies from "js-cookie";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import EditUser from "./Edit";
import EditUserPassword from "./EditPassword";
import Step1 from "./Step1";

const { REACT_APP_HOST_API: url } = process.env;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const items = [
  {
    key: 1,
    label: "Step 1",
    children: <Step1 />,
  },
  {
    key: 2,
    label: "Step 2",
    children: (
      <h1 className="text-xl my-3">
        Setelah Pembayaran silahkan Upload bukti bayar di Dashboard Pengajuan
        Gold
      </h1>
    ),
  },
  {
    key: 3,
    label: "Step 3",
    children: (
      <h1 className="text-xl my-3">
        Setelah Upload Bukti bayar silahkan tunggu admin untuk menerima
        pengajuan!
      </h1>
    ),
  },
];

const MasterUser = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataSubmission, setDataSubmission] = useState({});

  const [showPayment, setShowPayment] = useState(false);
  const [activeKey, setActiveKey] = useState(1);

  const [fileList, setFileList] = useState([]);
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idBooking, setIdBooking] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [visible, setVisible] = useState(false);

  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const { data, isLoading, refetch } = useUsersDetail(userId);

  const fetchSubmission = useCallback(async () => {
    setLoadingSubmission(true);
    try {
      const { data } = await axios.get(
        url + `/api/upgrade-members?page=1&limit=1000000`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      const filteredData = await data?.data?.data?.find(
        (x) => x.user_id === userId
      );

      setDataSubmission(filteredData);
    } catch (error) {
      alert("Error get data" + error.message);
    } finally {
      setLoadingSubmission(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) refetch();
  }, [refetch, userId]);

  useEffect(() => {
    if (data?.data) {
      encryptCookies("dataUser", JSON.stringify(data.data));
      fetchSubmission();
    }
  }, [data, fetchSubmission]);

  const onCancel = () => {
    setShowEdit(false);
    setShowEditPassword(false);
  };

  const onUploadBuktiBayar = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        url + "/api/upgrade-members",
        { id: idBooking, image: fileList[0]?.originFileObj, userId },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setShowModal(false);
      setFileList([]);
      fetchSubmission();
      message.success(data.message);
    } catch (error) {
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onUpgradeMembership = async () => {
    try {
      await axios.post(
        url + "/api/upgrade-members",
        { userId },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      setOpen(false);
      fetchSubmission();
      Modal.info({
        content: (
          <h1 className="text-2xl font-semibold">
            Selamat!
            <br />
            anda telah mengajukan Gold Membership! Selanjutnya silahkan lakukan
            pembayaran
          </h1>
        ),
      });
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const onCancelModal = () => {
    setShowModal(false);
    setFileList([]);
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="flex flex-col md:flex-row gap-10 relative h-auto">
            <Image
              width={200}
              src={data?.data?.image}
              className="rounded-md border"
            />
            <table className="w-full max-w-[500px]">
              <tbody>
                <tr className="border-b">
                  <td className="w-1/2 py-2">Username</td>
                  <td className="w-1/2 py-2">{data?.data?.username}</td>
                </tr>
                <tr className="border-b">
                  <td className="w-1/2 py-2">Company</td>
                  <td className="w-1/2 py-2">{data?.data?.company_name}</td>
                </tr>
                <tr className="border-b">
                  <td className="w-1/2 py-2">Phone</td>
                  <td className="w-1/2 py-2">{data?.data?.phone}</td>
                </tr>
                <tr className="border-b">
                  <td className="w-1/3 py-2">Membership</td>
                  <td className="w-1/3 py-2">
                    <Space>
                      <p>{data?.data?.member?.name}</p>
                      <BsStars
                        color={`${
                          data?.data?.member?.name === "Gold"
                            ? "orange"
                            : "gray"
                        }`}
                        className="text-[18px]"
                      />
                    </Space>
                  </td>

                  {!dataSubmission && (
                    <td
                      className="w-1/3 py-2"
                      hidden={data?.data?.member?.name === "Gold"}
                    >
                      <Tag
                        color="orange"
                        className="cursor-pointer"
                        onClick={() => setOpen(true)}
                      >
                        Upgrade to Gold
                      </Tag>
                    </td>
                  )}
                </tr>
                {data?.data?.member?.name === "Gold" && (
                  <tr className="border-b">
                    <td className="w-1/2 py-2">Expired date</td>
                    <td className="w-1/2 py-2">
                      {moment(data?.data?.expire_at).format("LL")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="absolute top-1 right-1 flex flex-col items-end">
              <Button
                size="middle"
                type="primary"
                className="flex items-center mb-2"
                onClick={() => setShowEdit(true)}
              >
                <EditOutlined />
                Edit Profile
              </Button>
              <Button
                size="middle"
                className="flex items-center mb-2"
                onClick={() => setShowEditPassword(true)}
              >
                <EditOutlined />
                Reset Password
              </Button>
            </div>
          </div>
        )}

        {!loadingSubmission &&
        dataSubmission &&
        dataSubmission?.status !== "accepted" ? (
          <div className="mt-20 mb-4">
            <DividerComponent>
              <h2 className="text-xl font-bold">Pengajuan Gold Member</h2>
            </DividerComponent>
            <table className="w-full max-w-[500px]">
              <tbody>
                {dataSubmission?.status !== "accepted" && (
                  <tr className="border-b">
                    <td className="w-1/2 py-2">
                      <Button
                        block
                        onClick={() => setShowPayment(true)}
                        className=" border-gray-500"
                      >
                        Lihat Cara Pembayaran
                      </Button>
                    </td>
                    <td className="w-1/2 py-2"></td>
                  </tr>
                )}
                <tr className="border-b">
                  <td className="w-1/2 py-2">Tanggal Pengajuan</td>
                  <td className="w-1/2 py-2">
                    {moment(dataSubmission?.created_at).format("LL")}
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="w-1/2 py-2">Status</td>
                  <td className="w-1/2 py-2">{dataSubmission?.status}</td>
                </tr>
                <tr className="border-b">
                  <td className="w-1/2 py-2">Bukti Bayar</td>
                  <td className="w-1/2 py-2">
                    {dataSubmission?.status === "waiting" &&
                      !dataSubmission?.image && (
                        <Tag
                          color="blue-inverse"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowModal(true);
                            setIdBooking(dataSubmission?.id);
                          }}
                        >
                          Upload bukti bayar
                        </Tag>
                      )}
                    {dataSubmission?.image && (
                      <Tag
                        color="blue-inverse"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImgUrl(dataSubmission?.image);
                          setVisible(true);
                        }}
                      >
                        Lihat bukti bayar
                      </Tag>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
      <EditUser show={showEdit} onCancel={onCancel} onUpdate={refetch} />
      <EditUserPassword
        show={showEditPassword}
        onCancel={onCancel}
        onUpdate={refetch}
      />
      <Modal
        open={open}
        title=""
        onOk={onUpgradeMembership}
        onCancel={() => setOpen(false)}
        okText="Ya"
        closable
      >
        <h1 className="text-xl">Anda yakin akan upgrade ke Gold?</h1>
      </Modal>

      <Modal
        open={showModal}
        okText="Submit"
        onOk={onUploadBuktiBayar}
        onCancel={onCancelModal}
        okButtonProps={{ loading }}
      >
        <Upload
          accept=".jpg,.jpeg,.png"
          listType="picture-card"
          onPreview={handlePreview}
          beforeUpload={(file) => {
            if (file.size <= 3000000) {
              return false;
            }
            if (file.size > 3000000) {
              message.error("Maks File 3MB");
            }
          }}
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
          onChange={(file) => {
            if (file?.file.size > 3000000) return;
            else setFileList(file?.fileList);
          }}
          fileList={fileList}
        >
          {fileList.length > 0 ? null : uploadButton}
        </Upload>
      </Modal>
      <Image
        width={200}
        style={{ display: "none" }}
        src={imgUrl}
        preview={{
          visible,
          src: imgUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />

      <Modal
        open={showPayment}
        okButtonProps={{ loading }}
        onCancel={() => {
          setShowPayment(false);
          setActiveKey(1);
        }}
        footer={[
          <Button
            key="1"
            onClick={() => {
              if (activeKey > 1) setActiveKey(activeKey - 1);
              else return;
            }}
          >
            Sebelumnya
          </Button>,
          <Button
            key="2"
            type="primary"
            loading={loading}
            onClick={() => {
              if (activeKey < 3) setActiveKey(activeKey + 1);
              else {
                setShowPayment(false);
                setActiveKey(1);
              }
            }}
          >
            {activeKey < 3 ? "Selanjutnya" : "OK"}
          </Button>,
        ]}
      >
        <Tabs
          items={items}
          defaultActiveKey={1}
          activeKey={activeKey}
          onChange={(e) => setActiveKey(e)}
        />
      </Modal>
    </>
  );
};

export default MasterUser;
