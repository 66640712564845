import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productsId: "",
  fetchProducts: false,
  showCreateProducts: false,
  showEditProducts: false,
  showDetailProducts: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProductsId: (state, action) => {
      state.productsId = action.payload;
    },
    setFetchProducts: (state, action) => {
      state.fetchProducts = action.payload;
    },
    setShowCreateProducts: (state, action) => {
      state.showCreateProducts = action.payload;
    },
    setShowEditProducts: (state, action) => {
      state.showEditProducts = action.payload;
    },
    setShowDetailProducts: (state, action) => {
      state.showDetailProducts = action.payload;
    },
  },
});

export const {
  setProductsId,
  setFetchProducts,
  setShowCreateProducts,
  setShowEditProducts,
  setShowDetailProducts,
} = productsSlice.actions;

export default productsSlice.reducer;
