import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setAboutHeadlineId,
  setFetchAboutHeadline,
  setShowCreateAboutHeadline,
  setShowEditAboutHeadline,
} from "features/about-us/aboutus-slice";
import { useAboutHeadline } from "hook/about-us-hook/apiCalls";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import CreateAboutHeadline from "./Create";
import { useEffect } from "react";
import EditAboutHeadline from "./Edit";

const AboutHeadline = () => {
  const dispatch = useDispatch();
  const { fetchAboutHeadline } = useSelector((state) => state.about);
  const userToken = useSelector((state) => state.auth.token);

  const { data: dataHeadline, refetch } = useAboutHeadline();

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchAboutHeadline(false));
  }, [refetch, fetchAboutHeadline, dispatch]);

  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;

  return (
    <div className="relative h-[70vh] mb-[200px]">
      {dataHeadline?.data ? (
        <>
          <img
            src={dataHeadline?.data?.image}
            alt="headline"
            className="w-[100vw] h-[70vh] object-cover object-center"
          />
          <div className="w-[600px] max-w-[90vw] absolute left-[50%] translate-x-[-50%] md:translate-x-0 md:left-[40px] bottom-[-110px] bg-[#0C2D57] p-6 md:p-10 rounded-md border border-gray-300 shadow-lg">
            <h1 className="text-2xl md:text-4xl font-[500] mb-0 md:mb-4 text-gray-200">
              {dataHeadline?.data?.text}
            </h1>
            <p className="text-[14px] md:text-[1em] font-[300] font-[Onest] text-gray-300">
              {dataHeadline?.data?.description}
            </p>
          </div>
          {role === "admin_user" && userToken && (
            <Button
              icon={<EditOutlined />}
              type="primary"
              disabled={role !== "admin_user"}
              hidden={role !== "admin_user"}
              onClick={() => {
                if (role === "admin_user") {
                  dispatch(setShowEditAboutHeadline(true));
                  dispatch(setAboutHeadlineId(dataHeadline?.data?.id));
                } else return;
              }}
              className="absolute top-[20px] right-10 z-30 flex items-center drop-shadow-xl font-[300] mt-10"
            >
              Edit Headline
            </Button>
          )}
        </>
      ) : (
        role === "admin_user" && (
          <Button
            icon={<PlusCircleOutlined />}
            disabled={role !== "admin_user"}
            hidden={role !== "admin_user" && dataHeadline?.data}
            onClick={() => {
              if (role === "admin_user")
                dispatch(setShowCreateAboutHeadline(true));
              else return;
            }}
            className="absolute top-[20px] right-10 z-30 flex items-center drop-shadow-xl font-[300] mt-20"
          >
            Create Headline
          </Button>
        )
      )}

      <CreateAboutHeadline />
      <EditAboutHeadline />
    </div>
  );
};

export default AboutHeadline;
