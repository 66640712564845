import { Input, Popconfirm, Space, Table, Tag } from "antd";
import TableHeader from "components/TableHeader";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteApi } from "../../../api/DeleteApi";
import { TestimonialContext } from "../../../context/TestimonialContext";
import { useTestimonialPagination } from "../../../hook/testimonial-hook/apiCalls";
import CreateTestimonial from "./CreateTestimonial";
import EditTestimonial from "./EditTestimonial";

const MasterTestimonial = () => {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const navigate = useNavigate();
  const {
    setShowCreate,
    setShowEdit,
    fetchTestimonial,
    setFetchTestimonial,
    setTestimonialId,
  } = useContext(TestimonialContext);

  const { data, isLoading, refetch } = useTestimonialPagination({
    keyword,
    dataTable,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  useEffect(() => {
    refetch();

    return () => setFetchTestimonial(false);
  }, [refetch, fetchTestimonial, setFetchTestimonial]);

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "name" },
    { title: "Posisi", dataIndex: "position" },
    { title: "Order", dataIndex: "order" },
    {
      title: "Tampil di Homepage",
      dataIndex: "show_on_home",
      render: (x) => (x === true ? "Tampil" : "Tidak"),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({ url: "/api/testimonis/", dataId: id, refetch })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowEdit(true);
              setTestimonialId(id);
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(id)}
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader title="Testimonials" onClick={() => setShowCreate(true)} />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />

      <CreateTestimonial />
      <EditTestimonial />
    </div>
  );
};

export default MasterTestimonial;
