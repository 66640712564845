import AuthBg from "assets/login2.jpg";
import Login from "pages/login/Login";
import UserSignUp from "pages/sign-up";
import { useSelector } from "react-redux";

const h1Style = "text-2xl font-bold mb-6";

const UserAuth = () => {
  const { authContent } = useSelector((state) => state.auth);

  return (
    <div className="w-full mx-auto h-screen md:h-auto block md:flex items-center gap-6 md:gap-20 dark:bg-white dark:text-black">
      <img
        src={AuthBg}
        alt="auth-bg"
        className="w-full h-[300px] md:h-screen object-cover object-bottom md:block md:w-[35%] mb-4 md:mb-0"
      />
      <div className="w-[90%] mx-auto md:mx-0 md:w-[65%] pb-16 md:pb-0">
        {authContent === "sign-in" ? (
          <>
            <h1 className={h1Style}>Sign In to Biznids</h1>
            <Login />
          </>
        ) : (
          <>
            <h1 className={h1Style}>Sign Up to Biznids</h1>
            <UserSignUp />
          </>
        )}
      </div>
    </div>
  );
};

export default UserAuth;
