import { Button, Modal, Skeleton } from "antd";
import { setShowDetailProducts } from "features/products/products-slice";
import { useProductsDetail } from "hook/products-hook/apiCalls";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const DetailProducts = () => {
  const dispatch = useDispatch();
  const { productsId, showDetailProducts } = useSelector(
    (state) => state.products
  );

  const { data, isLoading, refetch } = useProductsDetail({ id: productsId });

  useEffect(() => {
    if (!productsId) return;
    refetch();
  }, [refetch, productsId]);

  const onCancel = () => dispatch(setShowDetailProducts(false));

  return (
    <Modal
      open={showDetailProducts}
      okText="Submit"
      cancelText="Batal"
      style={{ top: 20 }}
      width={800}
      closable
      onCancel={onCancel}
      footer={[
        <Button key={1} type="primary" onClick={onCancel}>
          Ok
        </Button>,
      ]}
    >
      {isLoading ? (
        new Array(4).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <div>
          <div className="flex gap-4 flex-wrap">
            <img src={data?.data?.banner} alt="banner" className="w-[300px]" />
            <ul className="w-full">
              <li className="py-2 border-b border-b-gray-200">
                {data?.data?.name}
              </li>
              <li className="py-2 border-b border-b-gray-200">
                {data?.data?.description}
              </li>
              <li className="py-2 border-b border-b-gray-200">
                Rp
                {data?.data?.price
                  ? data?.data?.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : ""}
              </li>
              <li className="py-2 border-b border-b-gray-200">
                <a
                  href={data?.data?.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500"
                >
                  Link Produk
                </a>
              </li>
            </ul>
          </div>

          <div className="flex mt-10 gap-4 flex-wrap">
            {data?.data?.images?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt="product"
                className="w-[200px] object-contain object-center"
              />
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DetailProducts;
