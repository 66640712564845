import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Upload,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { decryptCookies } from "../../../components/helper/cookieshelper";
import RichTextEditor from "@mantine/rte";
import Loading from "components/Loading";
import { useNavigate, useParams } from "react-router-dom";

const date = "YYYY-MM-DD HH:mm:ss";
const { REACT_APP_HOST_API } = process.env;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditNews = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newBannerList, setNewBannerList] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [value, setValue] = useState("");

  const { news_id } = useParams();
  const navigate = useNavigate();
  const html2json = require("html2json").html2json;
  const json2html = require("html2json").json2html;

  const fetchDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/api/news-info-contents/${news_id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      if (data?.data?.banner) {
        const image = data.data.banner;
        const newImage = {
          uid: 1,
          name: image,
          status: "done",
          url: image,
        };

        setBannerList([newImage]);
      }

      setValue(json2html(data?.data?.content));
      form.setFieldsValue({
        order: data?.data?.order,
        showOnHome: data?.data?.show_on_home,
        title: data?.data?.title,
        content: json2html(data?.data?.content),
        published: dayjs(moment(data?.data?.published).format(date)),
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }, [form, json2html, news_id]);

  useEffect(() => {
    fetchDetail();

    return () => {
      setDeletedImages([]);
      setNewBannerList([]);
    };
  }, [fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setBannerList([]);
    setNewBannerList([]);
    setDeletedImages([]);
    navigate(-1);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + "/api/news-info-contents/" + news_id,
        deletedImages === 0 && newBannerList === 0
          ? {
              ...values,
              content: html2json(value),
              published: values?.published?.format(date),
            }
          : {
              ...values,
              content: html2json(value),
              published: values?.published?.format(date),
              banner: newBannerList[0],
              deleteImages: deletedImages.length > 0 && deletedImages.join(","),
            },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data.message);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Form form={form} layout="vertical">
        <Form.Item label="Gambar Banner" rules={[{ required: true }]}>
          <Upload
            fileList={bannerList}
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setBannerList(file.fileList);
            }}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setBannerList([file]);
                setNewBannerList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
          >
            {bannerList?.length > 1
              ? null
              : bannerList.length === 0
              ? uploadButton
              : "Ganti"}
          </Upload>
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[
            { required: true },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Masukkan hanya Nomor",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="showOnHome" label="Tampil di Homepage">
          <Radio.Group name="showOnHome">
            <Radio value={true}>Tampil</Radio>
            <Radio value={false}>Tidak</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="title"
          label="Judul News"
          rules={[{ min: 3, required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="published"
          label="Published"
          rules={[{ required: true }]}
        >
          <DatePicker showTime />
        </Form.Item>

        <Form.Item name="content" label="Description" rules={[{ min: 3 }]}>
          <RichTextEditor
            value={value}
            onChange={setValue}
            id="rte"
            controls={[
              ["bold", "italic", "underline", "link"],
              ["h1", "h2", "h3"],
              ["unorderedList", "orderedList"],
              ["alignLeft", "alignCenter", "alignRight"],
            ]}
          />
        </Form.Item>

        <div className="my-4 flex justify-end gap-4">
          <Button onClick={onCancelModal}>Batal</Button>
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Submit
          </Button>
        </div>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default EditNews;
