import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useFreeLearningsPagination = ({
  keyword = "",
  date,
  dataTable,
  upComing,
  showGuest,
}) => {
  return useQuery(
    [
      "get-freeLearnings-pagination",
      keyword,
      date?.from,
      date?.to,
      dataTable?.page,
      dataTable?.limit,
      upComing,
      showGuest,
    ],
    () =>
      GetUrl(
        `/api/biznids-learning/free?page=${dataTable.page}&limit=${
          dataTable.limit
        }&keyword=${keyword}&upComing=${upComing}&fromDate=${
          date?.from ? date?.from : ""
        }&toDate=${date?.to ? date?.to : ""}&showGuest=${
          showGuest ? showGuest : ""
        }`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useFreeParticipants = ({ id, keyword = "", dataTable }) => {
  return useQuery(
    [
      "get-freeLearningsParticipants-pagination",
      id,
      keyword,
      dataTable.page,
      dataTable.limit,
    ],
    () =>
      GetUrl(
        `/api/biznids-learning/guest-free?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}&freeWebinarId=${id}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useFreeLearningsDetail = (id) => {
  return useQuery(
    ["get-freeLearnings-detail", id],
    () => GetUrl(`/api/biznids-learning/free/${id}`),
    { refetchOnWindowFocus: true, enabled: false }
  );
};

// paid learnings start
export const usePaidLearningsPagination = ({
  keyword = "",
  date,
  dataTable,
  upComing,
  showGuest,
  showLink,
  userId,
  showOnHome,
}) => {
  return useQuery(
    [
      "get-paidLearnings-pagination",
      keyword,
      date?.from,
      date?.to,
      dataTable?.page,
      dataTable?.limit,
      upComing,
      showGuest,
      showLink,
      showOnHome,
      userId,
    ],
    () =>
      GetUrl(
        `/api/biznids-learning/paid?page=${dataTable.page}&limit=${
          dataTable.limit
        }&keyword=${keyword}&fromDate=${date?.from ? date?.from : ""}&toDate=${
          date?.to ? date?.to : ""
        }&showGuest=${showGuest ? showGuest : ""}&upComing=${
          upComing ? upComing : ""
        }&showLink=${showLink ? showLink : ""}&showOnHome=${
          showOnHome ? showOnHome : ""
        }&userId=${userId ? userId : ""}`
      ),
    { refetchOnWindowFocus: true }
  );
};

export const usePaidParticipants = ({
  id,
  userId,
  keyword = "",
  dataTable,
}) => {
  return useQuery(
    [
      "get-paidLearningsParticipants-pagination",
      id,
      userId,
      keyword,
      dataTable.page,
      dataTable.limit,
    ],
    () =>
      GetUrl(
        `/api/biznids-learning/user-paid?page=${dataTable.page}&limit=${
          dataTable.limit
        }&keyword=${keyword}&paidWebinarId=${id}&userId=${userId ? userId : ""}`
      ),
    { refetchOnWindowFocus: true }
  );
};

export const usePaidLearningsDetail = ({ id, showLink, showGuest }) => {
  return useQuery(
    ["get-paidLearnings-detail", id],
    () =>
      GetUrl(
        `/api/biznids-learning/paid/${id}?showLink=${
          showLink ? showLink : ""
        }&showGuest=${showGuest ? showGuest : ""}`
      ),
    { refetchOnWindowFocus: true, enabled: false }
  );
};

export const useLearningsHeadline = () => {
  return useQuery(
    "get-webinars-headline",
    () => GetUrl(`/api/biznids-learning/headline?page=1&limit=10`),
    { refetchOnWindowFocus: false }
  );
};
