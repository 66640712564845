import React from "react";
import Headline from "./Headline";
import Events from "./Events";
import { useTradeMissionPagination } from "hook/trade-mission-hook/apiCalls";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

const TradeMissions = () => {
  const { keyword, date } = useSelector((state) => state.tradeMission);

  const [debouncedKeyword] = useDebounce(keyword, 2000);

  const { data: dataUpcoming } = useTradeMissionPagination({
    dataTable: { page: 1, limit: 10000000 },
    keyword: debouncedKeyword,
    date,
    upComing: 1,
  });
  const { data: dataPast } = useTradeMissionPagination({
    dataTable: { page: 1, limit: 10000000 },
    keyword: debouncedKeyword,
    date,
    upComing: 0,
  });

  return (
    <div className="mt-[60px]">
      <Headline />
      <Events dataUpcoming={dataUpcoming} dataPast={dataPast} />
    </div>
  );
};

export default TradeMissions;
