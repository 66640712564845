import { decryptCookies } from 'components/helper/cookieshelper';
import { useBusinessMatchingDetail } from 'hook/business-matching-hook/apiCalls';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const PaymentMatching = () => {
	const { matching_id } = useParams();

	const role =
		Cookies.get('dataUser') && JSON.parse(decryptCookies('dataUser')).role;

	const { data, refetch } = useBusinessMatchingDetail({ id: matching_id });

	useEffect(() => {
		refetch();
	}, [refetch]);

	return (
		<div className='w-full h-screen flex items-center justify-center'>
			<div className='max-w-[700px] border border-gray-300 p-5 md:p-10 rounded-md'>
				<h1 className='text-3xl md:text-4xl font-[500] mb-5'>
					Selamat! anda telah mengajukan meet di event
					<br />
					<span className='bg-yellow-200 inline-block'>
						{data?.data?.title}
					</span>
					<br />
					Silahkan cek{' '}
					<Link
						to={`${
							role === 'personal_user'
								? `/user/business-matching/${data?.data?.id}/bookings`
								: role === 'corporate_user'
								? '/corporate/learnings'
								: '/admin'
						}`}
						className='text-blue-600 underline'>
						Dashboard
					</Link>{' '}
					anda untuk melakukan pembayaran
				</h1>
			</div>
		</div>
	);
};

export default PaymentMatching;
