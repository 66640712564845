import { PlusOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Image,
  Input,
  Modal,
  Space,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import axios from "axios";
import TableHeader from "components/TableHeader";
import { decryptCookies } from "components/helper/cookieshelper";
import { setFetchPaidLearnings } from "features/learnings/learnings-slice";
import { useBanksPagination } from "hook/banks-hook/apiCalls";
import { usePaidLearningsPagination } from "hook/learnings-hook/apiCalls";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { REACT_APP_HOST_API } = process.env;

const UserPaidLearnings = () => {
  const [keyword, setKeyword] = useState("");
  const [showBanks, setShowBanks] = useState(false);
  const [date, setDate] = useState({ from: "", to: "" });
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idBooking, setIdBooking] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const { fetchPaidLearnings } = useSelector((state) => state.learnings);

  const { data: dataBanks } = useBanksPagination({
    dataTable: { page: 1, limit: 100 },
  });

  const { data, isLoading, refetch } = usePaidLearningsPagination({
    date,
    keyword,
    dataTable,
    userId,
    showGuest: 1,
  });

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchPaidLearnings(false));
  }, [refetch, dispatch, fetchPaidLearnings]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const onUpdate = async () => {
    if (fileList.length === 0) {
      alert("Harap input gambar");
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + "/api/biznids-learning/user-paid/" + idBooking,
        { image: fileList[0]?.originFileObj },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data.message);
      refetch();
      onCancel();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const dataSource = data?.data?.data?.map((x, i) => {
    const userBook = x?.userPaidWebinar[0];

    return {
      ...x,
      key: x.id,
      index: i + 1,
      status: userBook?.status,
      link: userBook?.link,
      code: userBook?.code_unique,
    };
  });

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "title" },
    {
      title: "Waktu Mulai",
      dataIndex: "from_date",
      render: (x) => moment(x).format("LL - HH:mm"),
    },
    // { title: "Speaker", dataIndex: "speaker" },
    // {
    //   title: "Waktu Selesai",
    //   dataIndex: "to_date",
    //   render: (x) => moment(x).format("LL - HH:mm"),
    // },
    {
      title: "Link Learning",
      dataIndex: "link",
      render: (x) => (x ? x : "Menunggu konfirmasi"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <Badge
          status={
            moment(new Date()).format("YYYY-MM-DD") >
            moment(record.from_date).format("YYYY-MM-DD")
              ? "error"
              : record?.status === "waiting"
              ? "processing"
              : record?.status === "approve"
              ? "success"
              : "error"
          }
          text={
            moment(new Date()).format("YYYY-MM-DD") >
            moment(record.from_date).format("YYYY-MM-DD")
              ? "Expired"
              : record?.status === "waiting"
              ? "menunggu"
              : record?.status === "approve"
              ? "diterima"
              : "ditolak"
          }
        />
      ),
    },
    {
      title: "Jumlah Bayar",
      dataIndex: "code",
      render: (_, record) =>
        moment(new Date()).format("YYYY-MM-DD") <=
        moment(record.from_date).format("YYYY-MM-DD")
          ? "Rp" +
            record?.code?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          : "Expired",
    },
    {
      title: "Bukti Bayar",
      align: "center",
      render: (_, record) => (
        <Space>
          {record?.userPaidWebinar[0].status !== "approve" &&
            moment(new Date()).format("YYYY-MM-DD") <=
              moment(record.from_date).format("YYYY-MM-DD") && (
              <Tag
                color="blue"
                className="cursor-pointer"
                onClick={() => {
                  setIdBooking(record.userPaidWebinar[0].id);
                  setShowModal(true);
                }}
              >
                {record?.userPaidWebinar[0].image ? "Upload Ulang" : "Upload"}
              </Tag>
            )}
          {record?.userPaidWebinar[0].image && (
            <Tag
              color="blue"
              className="cursor-pointer"
              onClick={() => {
                setImgUrl(record?.userPaidWebinar[0].image);
                setVisible(true);
              }}
            >
              Lihat
            </Tag>
          )}
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };

  const onCancel = () => {
    setShowModal(false);
    setFileList([]);
  };

  return (
    <div>
      <TableHeader
        title="Biznids Learnings"
        textBtn="Cara Bayar"
        onClick={() => setShowBanks(true)}
      />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Awal"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e === null) setDate((curr) => ({ ...curr, from: "" }));
              else
                setDate((curr) => ({
                  ...curr,
                  from: e.format("YYYY-MM-DD"),
                }));
            }}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Akhir"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e === null) setDate((curr) => ({ ...curr, to: "" }));
              else
                setDate((curr) => ({
                  ...curr,
                  to: e.format("YYYY-MM-DD"),
                }));
            }}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 1000 }}
      />

      <Modal
        open={showModal}
        okText="Submit"
        onOk={onUpdate}
        onCancel={onCancel}
        okButtonProps={{ loading }}
      >
        <Upload
          accept=".jpg,.jpeg,.png"
          listType="picture-card"
          onPreview={handlePreview}
          beforeUpload={(file) => {
            if (file.size <= 2000000) {
              return false;
            }
            if (file.size > 2000000) {
              message.error("Maks File 2MB");
            }
          }}
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
          onChange={(file) => {
            if (file?.file.size > 2000000) return;
            else setFileList(file?.fileList);
          }}
          fileList={fileList}
        >
          {fileList.length > 0 ? null : uploadButton}
        </Upload>
      </Modal>

      <Modal
        open={showBanks}
        okText="Submit"
        onCancel={() => setShowBanks(false)}
        footer={[
          <Button key={1} type="primary" onClick={() => setShowBanks(false)}>
            Ok
          </Button>,
        ]}
      >
        <div>
          <h1 className="text-xl my-5">
            Silahkan bayar dengan Jumlah yang telah ditentukan ke salah satu
            rekening di bawah:
          </h1>
          <ul>
            {dataBanks?.data?.data?.map((bank) => (
              <li
                key={bank.id}
                className="mb-6 list-disc ml-6 border-b border-b-gray-300 pb-1 text-lg"
              >
                <span className="">
                  {bank.name} - {bank.no_rek}
                </span>
                <span className="block">an/ {bank.an}</span>
              </li>
            ))}
          </ul>
        </div>
      </Modal>

      <Image
        width={200}
        style={{
          display: "none",
        }}
        src={imgUrl}
        preview={{
          visible,
          src: imgUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />

      {/* <EditPaidLearnings /> */}
    </div>
  );
};

export default UserPaidLearnings;
