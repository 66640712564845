import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyword: "",
  date: { from: "", to: "" },
  matchingId: "",
  matchingHeadlineId: "",
  showCreate: false,
  showEdit: false,
  showCreateHeadline: false,
  showEditHeadline: false,
  fetchMatching: false,
  fetchMatchingHeadline: false,
};

const businessMatchingSlice = createSlice({
  name: "business-matching",
  initialState,
  reducers: {
    setMatchingId: (state, action) => {
      state.matchingId = action.payload;
    },
    setMatchingHeadlineId: (state, action) => {
      state.matchingHeadlineId = action.payload;
    },
    setShowCreate: (state, action) => {
      state.showCreate = action.payload;
    },
    setShowEdit: (state, action) => {
      state.showEdit = action.payload;
    },
    setShowCreateHeadline: (state, action) => {
      state.showCreateHeadline = action.payload;
    },
    setShowEditHeadline: (state, action) => {
      state.showEditHeadline = action.payload;
    },
    setFetchMatching: (state, action) => {
      state.fetchMatching = action.payload;
    },
    setFetchMatchingHeadline: (state, action) => {
      state.fetchMatchingHeadline = action.payload;
    },
    setFromDate: (state, action) => {
      state.date.from = action.payload;
    },
    setToDate: (state, action) => {
      state.date.to = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
});

export const {
  setMatchingId,
  setShowCreate,
  setShowEdit,
  setFetchMatching,
  setFromDate,
  setToDate,
  setKeyword,
  setShowEditHeadline,
  setFetchMatchingHeadline,
  setMatchingHeadlineId,
  setShowCreateHeadline,
} = businessMatchingSlice.actions;
export default businessMatchingSlice.reducer;
