import Container from "components/Container";
import FreeLearnings from "./free";
import PaidLearnings from "./paid";
import HeadlineLearnings from "./headline/Headline";

const Learnings = () => {
  return (
    <>
      <HeadlineLearnings />
      <Container className="mt-14">
        <div className="w-[90%] mx-auto">
          <h1 className="text-2xl font-bold mb-4">All Upcoming Learnings</h1>
          <FreeLearnings />
          <PaidLearnings />
        </div>
      </Container>
    </>
  );
};

export default Learnings;
