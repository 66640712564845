import { useProjectsDetail } from "hook/projects-hook/apiCalls";
import { json2html } from "html2json";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import parser from "html-react-parser";
import "./detailprojects.css";

const DetailProjects = () => {
  const { projects_id } = useParams();

  const { data, refetch } = useProjectsDetail(projects_id);

  useEffect(() => {
    if (!projects_id) return;
    refetch();
  }, [refetch, projects_id]);

  return (
    <div>
      <img
        src={data?.data?.banner}
        alt="banner"
        width={650}
        height={300}
        className="object-cover object-center max-w-full rounded-md mb-4"
      />

      <h1 className="text-xl md:text-2xl font-semibold">{data?.data?.title}</h1>
      <p>Penanggung Jawab: {data?.data?.responsibility}</p>
      <p className="mb-3">Tanggal: {data?.data?.date}</p>
      <div className="content-wrapper">
        {data
          ? data.data.content.child
            ? parser(json2html(data?.data?.content))
            : Object.values(data.data.content)
          : "No Content"}
      </div>

      {data ? (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 max-xl:grid-cols-4 gap-4 mt-6">
          {data.data?.images?.map((x, i) => (
            <img
              src={x}
              key={i}
              alt="projects"
              width={200}
              height={150}
              className="object-cover object-center"
            />
          ))}
        </div>
      ) : (
        "No Images"
      )}
    </div>
  );
};

export default DetailProjects;
