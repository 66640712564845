import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Page404 = () => {
  let navigate = useNavigate();

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="max-w-[600px] border border-gray-300 rounded-lg p-6 px-12 md:p-8 md:px-16">
        <h1 className="text-7xl">404</h1>
        <h2 className="text-3xl lg:text-4xl font-[500] mb-4">Page not found</h2>
        <Button
          type="primary"
          size="large"
          onClick={() => navigate(-1)}
          className="!rounded-sm"
        >
          Back to previous page
        </Button>
      </div>
    </div>
  );
};

export default Page404;
