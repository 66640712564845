import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyword: "",
  date: { from: "", to: "" },
  tradeId: "",
  tradeHeadlineId: "",
  showCreate: false,
  showEdit: false,
  showCreateHeadline: false,
  showEditHeadline: false,
  fetchTrade: false,
  fetchTradeHeadline: false,
};

const tradeMissionSlice = createSlice({
  name: "trade-mission",
  initialState,
  reducers: {
    setTradeId: (state, action) => {
      state.tradeId = action.payload;
    },
    setTradeHeadlineId: (state, action) => {
      state.tradeHeadlineId = action.payload;
    },
    setShowCreate: (state, action) => {
      state.showCreate = action.payload;
    },
    setShowEdit: (state, action) => {
      state.showEdit = action.payload;
    },
    setShowCreateHeadline: (state, action) => {
      state.showCreateHeadline = action.payload;
    },
    setShowEditHeadline: (state, action) => {
      state.showEditHeadline = action.payload;
    },
    setFetchTrade: (state, action) => {
      state.fetchTrade = action.payload;
    },
    setFetchTradeHeadline: (state, action) => {
      state.fetchTradeHeadline = action.payload;
    },
    setFromDate: (state, action) => {
      state.date.from = action.payload;
    },
    setToDate: (state, action) => {
      state.date.to = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
});

export const {
  setTradeId,
  setTradeHeadlineId,
  setShowCreate,
  setShowEdit,
  setShowEditPassword,
  setShowEditHeadline,
  setShowCreateHeadline,
  setFetchTrade,
  setFetchTradeHeadline,
  setFromDate,
  setToDate,
  setKeyword,
} = tradeMissionSlice.actions;
export default tradeMissionSlice.reducer;
