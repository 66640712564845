import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import Container from "components/Container";
import Section from "components/Section";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchVision,
  setShowCreateVision,
  setShowEditVision,
  setVisionId,
} from "features/about-us/aboutus-slice";
import { useAboutVision } from "hook/about-us-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateVision from "./Create";
import EditVision from "./Edit";
import Mission from "../mission/Mission";

const Vision = () => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);
  const { fetchVision } = useSelector((state) => state.about);

  const { data, refetch } = useAboutVision();

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchVision(false));
  }, [refetch, dispatch, fetchVision]);

  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;

  return (
    <>
      <Section>
        <Container className="md:px-10">
          <div>
            <h1 className="text-2xl md:text-4xl font-bold mb-2">
              Vision & Mission
            </h1>
            <div className="my-6 flex flex-col md:flex-row gap-20 mt-14">
              <div className="flex-1">
                {data?.data?.data?.length === 0 &&
                role === "admin_user" &&
                userToken ? (
                  <Button
                    icon={<PlusCircleOutlined />}
                    hidden={role !== "admin_user"}
                    disabled={role !== "admin_user"}
                    onClick={() => {
                      if (role === "admin_user")
                        dispatch(setShowCreateVision(true));
                      else return;
                    }}
                  >
                    Add Vision
                  </Button>
                ) : (
                  <>
                    <Space className="w-full mb-3 pb-3 border-b items-center">
                      <h1 className="text-xl font-[500]">
                        {data?.data?.data[0]?.title}
                      </h1>
                      {role === "admin_user" && (
                        <Button
                          size="small"
                          icon={<EditOutlined />}
                          disabled={role !== "admin_user"}
                          hidden={role !== "admin_user"}
                          onClick={() => {
                            if (role === "admin_user") {
                              dispatch(setVisionId(data.data.data[0]?.id));
                              dispatch(setShowEditVision(true));
                            } else return;
                          }}
                        >
                          Edit Vision
                        </Button>
                      )}
                    </Space>

                    <p>{data?.data?.data[0]?.text}</p>
                  </>
                )}
              </div>
              <Mission />
            </div>
          </div>
        </Container>
      </Section>
      <CreateVision />
      <EditVision />
    </>
  );
};

export default Vision;
