import { Image } from "antd";
import { useUsersDetail } from "hook/users-hook/apiCalls";
import { useParams } from "react-router-dom";
import "./detailmastercorporate.css";
import DividerComponent from "components/Divider";
import { Fragment, useEffect } from "react";
import Loading from "components/Loading";

const DetailMasterCorporate = () => {
  const { corporate_id } = useParams();

  const { data, isLoading, refetch } = useUsersDetail(corporate_id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="detail-corporate">
          <DividerComponent>Detail Perusahaan</DividerComponent>
          <Image width={300} src={data?.data?.image} />
          <table>
            <tbody>
              <tr>
                <td>Name: </td>
                <td>{data?.data?.username}</td>
              </tr>
              <tr>
                <td>Email: </td>
                <td>{data?.data?.email}</td>
              </tr>
              <tr>
                <td>No Handphone: </td>
                <td>{data?.data?.phone}</td>
              </tr>
              <tr>
                <td>Link Url: </td>
                <td>{data?.data?.url}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );
};

export default DetailMasterCorporate;
