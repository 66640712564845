import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Modal, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchProducts,
  setShowCreateProducts,
} from "features/products/products-slice";
import Cookies from "js-cookie";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { REACT_APP_HOST_API } = process.env;

const CreateProducts = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const dispatch = useDispatch();
  const { showCreateProducts } = useSelector((state) => state.products);

  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (fileList.length === 0) {
          return message.error("Harap masukkan gambar product");
        }

        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + "/api/products",
            {
              ...values,
              userId,
              banner: bannerList[0].originFileObj,
              images: fileList?.map((x) => x.originFileObj),
            },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          dispatch(setFetchProducts(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  const onCancelModal = () => {
    dispatch(setShowCreateProducts(false));
    form.resetFields();
    setFileList([]);
    setBannerList([]);
  };

  return (
    <Modal
      open={showCreateProducts}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={600}
      style={{ top: 20 }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nama Produk"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Deskripsi Product"
          rules={[{ required: true, min: 3 }]}
        >
          <TextArea rows={5} />
        </Form.Item>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <Form.Item name="price" label="Harga" rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link Product (jika ada)"
            rules={[{ min: 3 }]}
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item label="Gambar Thumbnail" rules={[{ required: true }]}>
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setBannerList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setBannerList(file?.fileList);
            }}
            fileList={bannerList}
          >
            <div>{bannerList.length === 0 ? uploadButton : "Ganti"}</div>
          </Upload>
        </Form.Item>
        <Form.Item label="Gambar Produk">
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 2MB");
              }
            }}
            onRemove={(file) => {
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
            }}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file?.fileList);
            }}
            fileList={fileList}
          >
            {fileList.length > 6 ? null : uploadButton}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default CreateProducts;
