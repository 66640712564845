import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";

const { REACT_APP_HOST_API } = process.env;

export const GetUrl = async (url, token) => {
  try {
    const { data } = await axios.get(
      REACT_APP_HOST_API + url,
      token && {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === "500") return;

    message.error(`Gagal mengambil data ( ${error.message} )`);
  }
};
