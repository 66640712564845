import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import { setAuthContent } from "features/auth/auth-slice";
import { useState } from "react";
import { useDispatch } from "react-redux";

const UserSignUp = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { REACT_APP_HOST_API: url } = process.env;

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      await axios.post(url + `/api/register`, {
        ...values,
        role: "personal_user",
      });
      alert("Berhasil register! silahkan cek email anda untuk aktivasi akun");
      form.resetFields();
    } catch (error) {
      if (error?.response?.data?.errors) {
        alert(error?.response?.data?.errors[0]?.message);
        return;
      }
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px]">
      <Form form={form} layout="vertical" size="middle">
        <div className="block md:flex justify-between gap-6">
          <Form.Item
            label="Username"
            name="username"
            rules={[
              { required: true, message: "Please fill this input!" },
              { min: 3, message: "Input minimum 3 character" },
            ]}
            className="w-full"
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ min: 3, message: "Input minimum 3 character" }]}
            className="w-full"
          >
            <Input size="large" />
          </Form.Item>
        </div>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please fill this input!" },
            { type: "email", message: "Please input a valid email" },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please fill this input!" },
            { min: 7, message: "Input minimum 7 character" },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Input only number",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item name="interest" label="Interest">
          <Select
            size="large"
            options={[
              {
                label: "Seller looking for a Buyer",
                value: "Seller looking for a Buyer",
              },
              {
                label: "Buyer looking for a Seller",
                value: "Buyer looking for a Seller",
              },
              {
                label: "Company looking for Investor",
                value: "Company looking for Investor",
              },
              {
                label: "Company looking for Partner/Supplier",
                value: "Company looking for Partner/Supplier",
              },
            ]}
          />
        </Form.Item>
        <div className="block md:flex justify-between gap-6">
          <Form.Item
            label="Password"
            name="password"
            hasFeedback
            rules={[{ required: true, min: 6 }]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            label="Password Confirmation"
            name="password_confirmation"
            hasFeedback
            rules={[
              { required: true },
              { min: 6 },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Password must be same!"));
                },
              }),
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
        </div>
        <Button
          type="primary"
          block
          size="large"
          onClick={handleSubmit}
          loading={loading}
        >
          Sign Up
        </Button>
      </Form>
      <p className="mt-4 text-gray-600 font-[300] text-right">
        Already have an account?{" "}
        <span
          className="text-blue-700 underline cursor-pointer"
          onClick={() => dispatch(setAuthContent("sign-in"))}
        >
          Sign In
        </span>
      </p>
    </div>
  );
};

export default UserSignUp;
