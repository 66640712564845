import { Form, Modal, Radio, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useCallback, useEffect, useState } from "react";

const { REACT_APP_HOST_API: url } = process.env;

const Edit = ({ id, show, onCancel, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(url + `/api/upgrade-members/${id}`, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      form.setFieldsValue({
        status: data?.data?.status,
      });
    } catch (error) {
      alert("Error: " + error.message);
    }
  }, [id, form]);

  useEffect(() => {
    if (show) fetchDetail();
  }, [id, show, fetchDetail]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(
        url + `/api/upgrade-members/${id}`,
        { status: values.status },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      onUpdate();
    } catch (error) {
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal
      open={show}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="status" label="Status">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="accepted">Accept</Radio.Button>
            <Radio.Button value="rejected">Reject</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Edit;
