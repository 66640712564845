import { createContext, useState } from "react";

export const CorporateContext = createContext();

export const CorporateProvider = ({ children }) => {
  const [corporateId, setCorporateId] = useState("");
  const [fetchCorporate, setFetchCorporate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <CorporateContext.Provider
      value={{
        corporateId,
        fetchCorporate,
        showCreate,
        showEdit,
        setCorporateId,
        setFetchCorporate,
        setShowCreate,
        setShowEdit,
      }}
    >
      {children}
    </CorporateContext.Provider>
  );
};
