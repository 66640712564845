import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useTradeMissionPagination = ({
  keyword = "",
  date,
  dataTable,
  upComing,
}) => {
  return useQuery(
    [
      "get-trade-mission-pagination",
      keyword,
      date.from,
      date.to,
      dataTable.page,
      dataTable.limit,
      upComing,
    ],
    () =>
      GetUrl(
        `/api/trade-missions?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}&upComing=${upComing}&fromDate=${date?.from}&toDate=${date?.to}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useTradeMissionDetail = (id) => {
  return useQuery(
    ["get-trade-mission-detail", id],
    () => GetUrl(`/api/trade-missions/${id}`),
    { refetchOnWindowFocus: true, enabled: false }
  );
};

export const useTradeHeadline = () => {
  return useQuery(
    "get-trade-headline",
    () => GetUrl(`/api/trade-mission-headlines?page=1&limit=10`),
    { refetchOnWindowFocus: false }
  );
};
