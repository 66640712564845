import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Tag, message } from "antd";
import axios from "axios";
import TableHeader from "components/TableHeader";
import { decryptCookies } from "components/helper/cookieshelper";
import { useBusinessMatchingDetail } from "hook/business-matching-hook/apiCalls";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddMatchingContributors from "./Add";

const { REACT_APP_HOST_API } = process.env;

const MasterMatchingContributors = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const navigate = useNavigate();
  const { matching_id } = useParams();

  const { data: dataDetail, refetch } = useBusinessMatchingDetail({
    id: matching_id,
  });

  const rowSelection = {
    onChange: (selectedRowKeys, _) => setSelectedRow(selectedRowKeys),
  };

  const dataSource = dataDetail?.data?.corporateEventOffline?.map((x, i) => ({
    ...x,
    key: x?.id,
    index: i + 1,
    name: x.corporate?.username,
    phone: x.corporate?.phone,
  }));

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "name" },
    { title: "No Handphone", dataIndex: "phone" },
    { title: "Jumlah Booking", dataIndex: "total_user_book" },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Tag
          color="blue"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(id)}
        >
          Bookings
        </Tag>
      ),
    },
  ];

  const onSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API + "/api/event-offline/corporates/batch",
        {
          data: { corporateEventOfflineIds: selectedRow },
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      setSelectedRow([]);
      message.success(data.message);
      refetch();
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <div>
      <TableHeader
        title={`Event Contributors - ${dataDetail?.data?.title}`}
        onClick={() => setShowModal(true)}
      />
      <div className="table-filter">
        <Popconfirm
          disabled={selectedRow.length === 0}
          title="Yakin ingin menghapus?"
          okText="Hapus"
          cancelText="Batal"
          okButtonProps={{ loading }}
          onConfirm={onSubmit}
        >
          <Button
            type="primary"
            danger
            disabled={selectedRow.length === 0}
            icon={<DeleteOutlined />}
          >
            Hapus
          </Button>
        </Popconfirm>
      </div>
      <Table
        rowSelection={{ ...rowSelection }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        scroll={{ x: 800 }}
      />
      <AddMatchingContributors
        showModal={showModal}
        onCancel={onCancel}
        onSuccess={refetch}
      />
    </div>
  );
};

export default MasterMatchingContributors;
