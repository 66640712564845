import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const TokenIsTrue = ({ children }) => {
  if (Cookies.get("token")) {
    return <Navigate to="/" />;
  }

  return children;
};

export default TokenIsTrue;
