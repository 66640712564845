import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  defaultAdminMenu: Cookies.get("defaultAdminMenu"),
  defaultUserMenu: Cookies.get("defaultUserMenu"),
  defaultCorporateMenu: Cookies.get("defaultCorporateMenu"),
};

const authSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setDefaultAdminMenu: (state, action) => {
      state.defaultAdminMenu = action.payload;
    },
    setDefaultUserMenu: (state, action) => {
      state.defaultUserMenu = action.payload;
    },
    setDefaultCorporateMenu: (state, action) => {
      state.defaultCorporateMenu = action.payload;
    },
  },
});

export const {
  setDefaultAdminMenu,
  setDefaultUserMenu,
  setDefaultCorporateMenu,
} = authSlice.actions;
export default authSlice.reducer;
