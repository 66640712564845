import Cookies from "js-cookie";

export const logout = () => {
  Cookies.remove("token");
  Cookies.remove("dataUser");
  Cookies.remove("defaultAdminMenu");
  Cookies.remove("defaultUserMenu");
  Cookies.remove("defaultCorporateMenu");
  Cookies.remove("isNavigate");
};
