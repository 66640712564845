import React from "react";
import { ScaleLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="fixed left-0 right-0 bottom-0 top-0 z-[999] bg-white flex items-center justify-center">
      <ScaleLoader width={4} color="royalblue" />
    </div>
  );
};

export default Loading;
