import { createContext, useState } from "react";

export const HeadlinesContext = createContext();

export const HeadlinesProvider = ({ children }) => {
  const [headlinesId, setHeadlinesId] = useState("");
  const [fetchHeadlines, setFetchHeadlines] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  return (
    <HeadlinesContext.Provider
      value={{
        headlinesId,
        fetchHeadlines,
        showCreate,
        showEdit,
        showDetail,
        setShowDetail,
        setHeadlinesId,
        setFetchHeadlines,
        setShowCreate,
        setShowEdit,
      }}
    >
      {children}
    </HeadlinesContext.Provider>
  );
};
