import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useAboutHeadline = () => {
  return useQuery("get-about-headlines-pagination", () =>
    GetUrl(`/api/aboutus/headline`)
  );
};

export const useHeadlinesDetail = (id) => {
  return useQuery(
    ["get-about-headlines-detail", id],
    () => GetUrl(`/api/aboutus/headline/${id}`),
    { refetchOnWindowFocus: false }
  );
};

export const useAboutVision = () => {
  return useQuery("get-about-vision", () => GetUrl(`/api/aboutus/visi`));
};

export const useAboutMission = () => {
  return useQuery("get-about-mission", () => GetUrl(`/api/aboutus/misi`));
};

export const useAboutTeamPagination = ({ dataTable, keyword = "" }) => {
  return useQuery(
    ["get-about-team-pagination", dataTable.page, dataTable.limit],
    () =>
      GetUrl(
        `/api/aboutus/ourteam?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      )
  );
};
